import { Button, Heading, Paragraph, SectionHeader } from '@swordhealth/ui-corporate';
import { Content, ButtonWrapper, UnitHeader, ImageWrapper, Arrow } from './styles';
import { Container } from '@/components/ui/flexbox';
import { products } from '@/utils/products';
import Image from 'next/image';
import Link from 'next/link';
import Section from '@/components/ui/Section';

const SolutionConditions = ({ content }) => {
  const { upperlabel, sectionHeader, conditions } = content;
  const { title, titleSize, titleSeoLevel, description, textLink, buttonGroup, xAlign } =
    sectionHeader;
  if (conditions.data.length < 1) return null;

  return (
    <Section>
      <Container>
        <SectionHeader
          title={title}
          label={upperlabel}
          titleSize={titleSize}
          titleSeoLevel={titleSeoLevel}
          description={description}
          textLink={textLink}
          buttonGroup={buttonGroup}
          xAlign={xAlign}
        />
        <Content>
          {conditions?.data?.map((solution) => {
            const { title, product, conditions } = solution.attributes;
            const { logo, productKey } = product.data.attributes;
            if (conditions.length > 0)
              return (
                <>
                  <UnitHeader>
                    <ImageWrapper>
                      <Image
                        fill
                        src={
                          logo
                            ? logo?.data?.attributes?.url
                            : products[productKey.toUpperCase()]?.logo
                        }
                      />
                    </ImageWrapper>
                    <Heading as="h3" size="xl">
                      {title}
                    </Heading>
                  </UnitHeader>
                  <ButtonWrapper>
                    {conditions.map((c, index) => (
                      <Button
                        key={`conditions_${productKey}_${index}`}
                        variant="tertiary"
                        as={Link}
                        href={`/eligibility-check?solution=${productKey}&hide_get_started=true&program_type=${
                          products[productKey.toUpperCase()]?.param
                        }`}
                      >
                        <Paragraph size="lg">{c.label}</Paragraph>
                        <Arrow />
                      </Button>
                    ))}
                  </ButtonWrapper>
                </>
              );
          })}
        </Content>
      </Container>
    </Section>
  );
};

export default SolutionConditions;
