import styled from 'styled-components';

import { Row } from '@/components/ui/flexbox';

export const CustomRow = styled(Row)`
  flex-wrap: nowrap;
  margin-top: ${({ $mgTop }) => ($mgTop ? '80px' : '23px')};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-top: 56px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 40px;
  }
`;

export default CustomRow;
