import Image from 'next/image';
import styled from 'styled-components';

import Quote from './Quote';
import QuoteContainer from './QuoteContainer';
import ImageWrapper from './ImageWrapper';

import getImgSrcWorkaround from '@/utils/images';

const Info = ({ id, quote, author, authorRole, image }) => {
  return (
    <Wrapper>
      <QuoteContainer>
        <Quote id={id} quote={quote} author={author} authorRole={authorRole} />
      </QuoteContainer>
      <ImageWrapper>
        <Image
          id={`testimonials-image-${id}`}
          src={getImgSrcWorkaround(image?.url ?? image?.data?.attributes?.url)}
          fill
          alt={image?.alternativeText ?? image.data.attributes.alternativeText ?? ''}
        />
      </ImageWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    max-width: 100%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-direction: column-reverse;
  }
`;

export default Info;
