import styled from 'styled-components';

export const LogoWrapper = styled.div`
  position: absolute;
  width: 160px;
  height: 24px;
  top: 40px;
  left: 40px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 108px;
    height: 16px;
    top: 24px;
    left: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 140px;
    height: 20px;
  }

  img {
    object-fit: contain;
    object-position: 0 center;
  }
`;

export default LogoWrapper;
