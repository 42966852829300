import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import classNames from 'classnames';
import useEmblaCarousel from 'embla-carousel-react';

import useReducedMotion from '@/utils/useReducedMotion';
import { Container } from '@/components/ui/flexbox';
import Section from '@/components/ui/Section';

import Card from '@/app/components/shared/CardWhatTreats';
import { Header, ListWrapper, CustomLabel, ExtraLabel } from './styles';
import theme from '@/utils/styles-variables';

import { Heading } from '@swordhealth/ui-corporate';

function scrollerReducer(state, action) {
  switch (action.type) {
    case 'toggle_autoplay':
      return {
        ...state,
        autoplay: action.value,
      };
    case 'toggle_scrollable':
      return {
        ...state,
        scrollable: action.value,
        autoplay: action.value,
      };
    case 'set_visibility':
      return {
        ...state,
        slidesInViewport: action.value,
      };
    default:
      return state;
  }
}

const WhatTreats = ({ content: { title, subtitle, imageLink, extraLabel } }) => {
  const [slidesToShow, setSlidesToShow] = useState(imageLink);
  const rafId = useRef(0);
  const windowWidth = useRef(0);
  const prefersReducedMotion = useReducedMotion();
  const [{ autoplay, scrollable, slidesInViewport }, dispatch] = useReducer(scrollerReducer, {
    autoplay: false,
    scrollable: false,
    slidesInViewport: [],
  });
  const [emblaRef, emblaApi] = useEmblaCarousel({
    containScroll: 'trimSnaps',
    dragFree: true,
    loop: true,
    inViewThreshold: 1,
  });

  const updateAria = useCallback(() => {
    if (!emblaApi) {
      return;
    }

    dispatch({
      type: 'set_visibility',
      value: emblaApi.slidesInView(),
    });
  }, [emblaApi]);

  const animate = useCallback(() => {
    if (!autoplay || !emblaApi || !rafId.current) return;

    const engine = emblaApi.internalEngine();

    if (!engine) return;

    engine.location.add(-1);
    engine.target.set(engine.location);
    engine.scrollLooper.loop(-1);
    engine.slideLooper.loop();
    engine.translate.to(engine.location);

    updateAria();

    rafId.current = requestAnimationFrame(animate);
  }, [autoplay, emblaApi, updateAria]);

  const stopAutoScroll = useCallback(() => {
    rafId.current = cancelAnimationFrame(rafId.current) || 0;
  }, []);

  const startAutoScroll = useCallback(() => {
    if (prefersReducedMotion || !autoplay) {
      stopAutoScroll();

      return;
    }

    // prevent duplicate rAFs
    if (rafId.current) {
      return;
    }

    rafId.current = requestAnimationFrame(animate);
  }, [autoplay, animate, prefersReducedMotion, stopAutoScroll]);

  const checkScroll = useCallback(() => {
    if (!emblaApi) {
      return;
    }
    const isMobile = window.innerWidth < theme.breakpoints.max_sm;
    const cardWidth = isMobile ? 224 : 288;
    const isScrollable = window.innerWidth < cardWidth * imageLink.length;

    dispatch({ type: 'toggle_scrollable', value: isScrollable });
    dispatch({
      type: 'toggle_autoplay',
      value: isScrollable,
    });

    if (isScrollable && !isMobile) {
      setSlidesToShow(imageLink.concat(imageLink));
    } else {
      setSlidesToShow(imageLink);
    }

    emblaApi.reInit({ active: isScrollable });
    startAutoScroll();
  }, [emblaApi, startAutoScroll]);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    function onResize() {
      if (windowWidth.current === window.innerWidth) return;

      windowWidth.current = window.innerWidth;
      checkScroll();
    }

    checkScroll();

    emblaApi.on('pointerDown', stopAutoScroll);
    emblaApi.on('select', updateAria);

    window.addEventListener('resize', onResize);

    return () => {
      stopAutoScroll();
      window.removeEventListener('resize', onResize);
    };
  }, [emblaApi, checkScroll, stopAutoScroll, updateAria]);

  return (
    <Section>
      <Container>
        <Header>
          <Heading id="what_treats_title" as="h2" size="2xl">
            {title}
          </Heading>
          {subtitle && <CustomLabel id="what_treats_subtitle">{subtitle}</CustomLabel>}
        </Header>
      </Container>
      <ListWrapper
        onMouseEnter={stopAutoScroll}
        onMouseLeave={startAutoScroll}
        className={classNames({
          scrollable,
        })}
        ref={emblaRef}
      >
        <ul
          className="embla__container"
          aria-roledescription="carousel"
          aria-live={prefersReducedMotion ? 'polite' : 'off'}
        >
          {slidesToShow.map((item, index) => (
            <li
              key={item.id + '_' + index}
              className="embla__slide"
              aria-roledescription="slide"
              aria-label={`${index + 1} of ${slidesToShow.length}`}
              aria-hidden={slidesInViewport.indexOf(index) < 0}
            >
              <Card {...item} id={`what_treats_${item.id}`} />
            </li>
          ))}
        </ul>
      </ListWrapper>
      {extraLabel && (
        <Container>
          <ExtraLabel id="what_treats_extra_label">{extraLabel}</ExtraLabel>
        </Container>
      )}
    </Section>
  );
};
export default WhatTreats;
