import styled from 'styled-components';
import Section, { SPACE_DESKTOP, SPACE_TABLET, SPACE_MOBILE } from '@/components/ui/Section';

const OuterWrapper = styled(Section).attrs({
  as: 'div',
})`
  background-color: ${(props) => props.theme.colors.neutral.default};
  padding-top: ${SPACE_DESKTOP};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-top: ${SPACE_TABLET};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: ${SPACE_MOBILE};
  }
`;

export default OuterWrapper;
