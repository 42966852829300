import Image from 'next/image';
import QRCode from 'react-qr-code';
import { useEffect, useMemo } from 'react';

import { Container } from '@/components/ui/flexbox';

import { WhiteButton } from '@/components/ui/Button';

import {
  CustomSection,
  DownloadNow,
  ImageWrapper,
  Wrapper,
  InfoWrapper,
  TitleWrapper,
  QRContainer,
} from './styles';

import { scrollToSection } from '@/utils/scrollToSection';
import getImgSrcWorkaround from '@/utils/images';
import useDetectMobileDevice from '@/utils/useDetectMobileDevice';

import theme from '@/utils/styles-variables';

import PlayStoreIcon from '@/public/assets/icons/app-store.svg';
import GooglePlayIcon from '@/public/assets/icons/google-play.svg';
import { Heading } from '@swordhealth/ui-corporate';

const AppSupport = ({ content }) => {
  const { active, title, image, download } = content;

  useEffect(() => {
    if (window.location.hash.toLocaleLowerCase() === '#appsupport')
      scrollToSection({ sectionId: 'AppSupport', isReduced: false });
  }, []);

  const isMobile = useDetectMobileDevice(true);
  const downloadUrls = useMemo(() => {
    if (!isMobile) {
      return {
        appStore: download.appStore || download.href,
        playStore: download.playStore || download.href,
      };
    }

    return {
      appStore: download.href,
      playStore: download.href,
    };
  }, [download, isMobile]);

  if (!active) {
    return null;
  }

  return (
    <CustomSection id="AppSupport">
      <Container large>
        <Wrapper>
          <InfoWrapper>
            <TitleWrapper>
              <Heading as="h2" size="3xl" id="app-support-title">
                {title}
              </Heading>
            </TitleWrapper>
            <DownloadNow>
              <div className="row">
                <WhiteButton id="app-support-google-button" href={downloadUrls.playStore}>
                  <GooglePlayIcon
                    id="app-support-google-icon"
                    aria-hidden="true"
                    className="icon"
                  />
                  Google Play
                </WhiteButton>

                <WhiteButton id="app-support-apple-button" href={downloadUrls.appStore}>
                  <PlayStoreIcon id="app-support-apple-icon" aria-hidden="true" className="icon" />
                  App Store
                </WhiteButton>
              </div>
              <QRContainer>
                <QRCode
                  id="app-support-qrcode"
                  size={80}
                  fgColor={theme.colors.grey.dark}
                  bgColor={theme.colors.neutral.default}
                  value={download.href}
                />
              </QRContainer>
            </DownloadNow>
          </InfoWrapper>
          <ImageWrapper>
            <Image
              id="app-support-image"
              alt={image?.alternativeText ?? image?.data?.attributes?.alternativeText ?? ''}
              fill
              sizes={`(max-width: ${theme.breakpoints.max_xs}px) 320px, ${theme.breakpoints.max_sm}px) 420px, ${theme.breakpoints.max_md}px 700px, 800px`}
              src={getImgSrcWorkaround(image?.url ?? image.data.attributes.url)}
            />
          </ImageWrapper>
        </Wrapper>
      </Container>
    </CustomSection>
  );
};
export default AppSupport;
