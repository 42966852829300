import Section from '@/components/ui/Section';

import { Counter } from '@swordhealth/ui-corporate';
import styled from 'styled-components';

const CounterSection = ({ content }) => {
  return (
    <StyledSection>
      <Counter {...content} counter={content?.counterValue} />
    </StyledSection>
  );
};

export default CounterSection;

const StyledSection = styled(Section)`
  padding-bottom: 0;
`;
