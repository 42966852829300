import Image from 'next/image';
import styled from 'styled-components';

import Quote from './Quote';
import QuoteContainer from './QuoteContainer';
import ImageWrapper from './ImageWrapper';
import LogoWrapper from './LogoWrapper';

import getImgSrcWorkaround from '@/utils/images';

const Info = ({ id, quote, customerName, companyName, image, logo }) => {
  return (
    <Wrapper>
      <QuoteContainer>
        <Quote id={id} quote={quote} customerName={customerName} companyName={companyName} />
      </QuoteContainer>
      <ImageWrapper>
        <Image
          id={`info-customer-image-${id}`}
          data-testid="info-image"
          src={getImgSrcWorkaround(image?.url ?? image?.data?.attributes?.url)}
          alt={image?.alternativeText ?? image?.data?.attributes?.alternativeText ?? ''}
          fill
        />
        {logo && (
          <LogoWrapper>
            <Image
              id={`info-logo-${id}`}
              data-testid="info-logo"
              src={getImgSrcWorkaround(logo?.url ?? logo?.data?.attributes?.url)}
              alt={logo?.alternativeText ?? logo.data.attributes.alternativeText ?? ''}
              fill
            />
          </LogoWrapper>
        )}
      </ImageWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  margin: 88px auto 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    max-width: 100%;
    justify-content: center;
    margin: 35px auto 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-direction: column;
    margin: 40px auto 0;
  }
`;

export default Info;
