import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { GroupChannelHandler, GroupChannelModule } from '@sendbird/chat/groupChannel';
import SendbirdChat from '@sendbird/chat';
import SendBirdDesk, { Ticket } from 'sendbird-desk';
const SendbirdProvider = dynamic(() => import('@sendbird/uikit-react/SendbirdProvider'), {
  ssr: false,
});
import SBChannelList from '@sendbird/uikit-react/GroupChannelList';
import '@sendbird/uikit-react/dist/index.css';
import {
  Container,
  InfoBalloon,
  MenuList,
  Panel,
  ActionButton,
  CountMessages,
  BackgroundCover,
} from './styles';

import Conversation from './Conversation';
import Loading from './Loading';
import { Paragraph } from '@swordhealth/ui-corporate';
import { RemoveScroll } from 'react-remove-scroll';
import theme from '@/utils/styles-variables';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import { trackEvent } from '@/lib/segment';
import { ACTIONS } from '@/utils/track';

const Channel = ({ unit, client, userId, isOpen = false }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [showPanel, setShowPanel] = useState(false);
  const [currentChannelUrl, setCurrentChannelUrl] = useState(null);
  const [currentTicket, setCurrentTicket] = useState(null);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [hideBubble, setHideBubble] = useState(false);
  const [ticketStatus, setTicketStatus] = useState('');
  const [isFirstMessage, setIsFirstMessage] = useState(false);
  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_xs}px)`);

  const app_id = process.env.NEXT_PUBLIC_APP_ID;

  const connectSendBird = async () => {
    if (!userId) return null;

    const params = {
      appId: app_id,
      modules: [new GroupChannelModule()],
    };
    const sb = SendbirdChat.init(params);
    try {
      await sb.connect(userId);

      await sb.groupChannel.addGroupChannelHandler(
        'sb_message_receive',
        new GroupChannelHandler({
          onMessageReceived: (message) => {
            verifyFirstMessage(message?.lastMessage?.sender?.userId);
            setUnreadMessages(unreadMessages + 1);
          },
          onUserLeft: (channel, user) => {
            if (channel.memberCount < 2 && user.userId !== userId) setTicketStatus('CLOSED');
          },
          onUserJoined: (channel, user) => {
            if (channel.memberCount > 1 && user.userId !== userId) {
              setTicketStatus('OPEN');
            }
          },
        }),
      );

      SendBirdDesk.init(sb);
      SendBirdDesk.authenticate(userId, () => {
        setAuthenticated(true);
      });
    } catch (error) {
      console.log(error);
    }
  };

  if (!authenticated) {
    const connect = async () => {
      await connectSendBird(userId);
    };

    connect();
  }

  useEffect(() => {
    if (isOpen && authenticated) {
      setHideBubble(true);
      createTicket();
    }
  }, [authenticated]);

  function createTicket() {
    if (currentTicket === null) {
      const ticketNum = ('000' + (new Date().getTime() % 1000)).slice(-3);
      const TICKET_TITLE = `Web member #${ticketNum}`;
      const customFields = {
        origin: 'landing-page',
        client: client,
        unit: unit,
      };

      Ticket.create(TICKET_TITLE, '', '', customFields, (ticket, error) => {
        if (error) {
          console.log(error);
        }
        setCurrentChannelUrl(ticket?.channelUrl);
        setCurrentTicket(ticket);
      });
    }
    setShowPanel(true);
  }

  function verifyFirstMessage(senderId) {
    if (senderId === userId && !isFirstMessage) {
      setIsFirstMessage(true);
    }
  }
  useEffect(() => {
    if (isFirstMessage)
      trackEvent(ACTIONS.MESSAGE_SENT, { message_number: 1, unit, client_key: client });
  }, [isFirstMessage]);

  function closeTickets() {
    Ticket.getByChannelUrl(currentChannelUrl, (ticket, error) => {
      if (error) {
        console.log(error);
      }
      ticket.close('', (error) => {
        if (error) {
          console.log(error);
        }
      });
    });
  }

  useEffect(() => {
    function beforeUnload() {
      closeTickets();
    }

    window.addEventListener('beforeunload', beforeUnload);

    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [currentChannelUrl]);

  const handleClick = () => {
    setUnreadMessages(0);
    if (showPanel) {
      setShowPanel(!showPanel);
      return;
    }
    trackEvent(ACTIONS.CHAT_OPENED, { unit, client_key: client });
    setHideBubble(true);
    createTicket();
  };
  if (!app_id) return null;
  return (
    <Container $show={showPanel}>
      <div id="sb-desk" class="-sbd-widget">
        <RemoveScroll enabled={showPanel && isMobile} removeScrollBar={false}>
          {isMobile && showPanel && <BackgroundCover onClick={() => handleClick()} />}
          <Panel $hide={!showPanel}>
            <SendbirdProvider appId={app_id} userId={userId}>
              <MenuList>
                <Loading visible={true} />
                <SBChannelList disableAutoSelect={true} />
              </MenuList>
              <Conversation
                channelUrl={currentChannelUrl}
                status={ticketStatus}
                setShowPanel={handleClick}
              />
            </SendbirdProvider>
          </Panel>
        </RemoveScroll>
        {authenticated && (
          <>
            <InfoBalloon $hide={hideBubble} onClick={() => handleClick()}>
              <Paragraph id="chat_bubble_id" size="sm">
                Chat with a Doctor of Physical Therapy.
              </Paragraph>
            </InfoBalloon>
            <ActionButton $isActive={showPanel} onClick={() => handleClick()}>
              {unreadMessages > 0 && !showPanel && <CountMessages>{unreadMessages}</CountMessages>}
            </ActionButton>
          </>
        )}
      </div>
    </Container>
  );
};

export default Channel;
