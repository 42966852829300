import dynamic from 'next/dynamic';

import Section from '@/components/ui/Section';

import Wrapper from './Wrapper';
import Title from './Title';
import Companies from './Companies';

const Marquee = dynamic(() => import('./StyledMarquee'), {
  loading: () => <span data-testid="marquee-loader" />,
  ssr: false,
});

const OneWayContainer = dynamic(() => import('./OneWayContainer'), {
  loading: () => <span data-testid="container-loader" />,
  ssr: false,
});

const TrustedCompanies = ({ content, colored }) => {
  const { title, companies } = content;

  return (
    <Section>
      <Wrapper>
        <OneWayContainer>
          <Title id="featured-in-title" data-testid="title">
            {title}
          </Title>
          <Marquee content={companies.data} colored={colored}>
            <Companies list={companies.data} />
          </Marquee>
        </OneWayContainer>
      </Wrapper>
    </Section>
  );
};

export default TrustedCompanies;
