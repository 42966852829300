import styled from 'styled-components';

const Title = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0;
  width: 133px;
  white-space: pre-line;
  opacity: 0.5;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    white-space: normal;
    width: 100%;
    text-align: center;
  }
`;

export default Title;
