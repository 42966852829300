import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    flex-direction: row-reverse;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-direction: column-reverse;
    > :first-child {
      align-self: start;
    }
  }
`;

export default Wrapper;
