import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import Image from 'next/image';
import theme from '@/utils/styles-variables';

export default function Logo() {
  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);

  return (
    <div>
      <Image
        loading="lazy"
        alt="Sword logo"
        src="/sword__colored.svg"
        height={isMobile ? 24 : 28}
        width={isMobile ? 100 : 116}
      />
    </div>
  );
}
