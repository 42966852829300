import Image from 'next/image';

import Section from '@/components/ui/Section';
import { Container } from '@/components/ui/flexbox';
import { TitleWrapper, List, Icon, ItemTitle, Text } from './styles';

import { Heading } from '@swordhealth/ui-corporate';

const EverythingYouNeed = ({ content }) => {
  const { title, iconDesc, id } = content;

  return (
    <Section>
      <Container>
        <TitleWrapper>
          <Heading size="2xl" as="h2" id={`everything-you-need-title-${id}`}>
            {title}
          </Heading>
        </TitleWrapper>
        <List $colspan={iconDesc.length}>
          {iconDesc.map((item, index) => (
            <li key={item.id}>
              <Icon>
                <Image
                  id={`everything-you-need-icon-${id}-${index}`}
                  src={item?.icon?.url ?? item?.icon?.data?.attributes?.url}
                  alt={
                    item?.icon?.alternativeText ??
                    item?.icon?.data?.attributes?.alternativeText ??
                    ''
                  }
                  width={80}
                  height={80}
                />
              </Icon>
              <ItemTitle id={`everything-you-need-item-title-${id}-${index}`}>
                {item.title}
              </ItemTitle>
              <Text id={`everything-you-need-description-${id}-${index}`}>{item.description}</Text>
            </li>
          ))}
        </List>
      </Container>
    </Section>
  );
};
export default EverythingYouNeed;
