import styled from 'styled-components';

import { Row, Col } from '@/components/ui/flexbox';
import Title, { UpperText, Label } from '@/components/ui/Title';

import List from './List';
import Wrapper from './Wrapper';

const SigningUp = ({ content }) => {
  if (!content) {
    return null;
  }

  const {
    title,
    signingUpList,
    media,
    upperLabel = 'Get Started',
    label = 'Start your journey in just 4 easy steps',
    wistiaID,
  } = content;

  if (!title || !signingUpList || !signingUpList.length) {
    return null;
  }

  return (
    <Wrapper>
      <Row>
        <Col xs={12} md={7}>
          {upperLabel && <UpperText data-testid="signing-up-upper-label">{upperLabel}</UpperText>}
          <StyledTitle small data-testid="signing-up-title">
            {title}
          </StyledTitle>
          {label && <Label data-testid="signing-up-label">{label}</Label>}

          {wistiaID && (
            <>
              <script src={`https://fast.wistia.com/embed/medias/${wistiaID}.jsonp`} async></script>
              <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
              <div
                className="wistia_responsive_padding"
                style={{ padding: '56.25% 0 0 0', position: 'relative' }}
                data-testid="signing-up-wistia"
              >
                <div
                  className="wistia_responsive_wrapper"
                  style={{ height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' }}
                >
                  <div
                    className={`wistia_embed wistia_async_${wistiaID} seo=false videoFoam=true`}
                    style={{ height: '100%', position: 'relative', width: '100%' }}
                  >
                    &nbsp;
                  </div>
                </div>
              </div>
            </>
          )}

          {!wistiaID && media && (
            <StyledImage
              src={media?.url ?? media?.data?.attributes?.url}
              alt={media?.alternativeText ?? media?.data?.attributes?.alternativeText ?? ''}
              data-testid="signing-up-media"
            />
          )}
        </Col>
        <Col xs={12} md={5}>
          <List list={signingUpList} />
        </Col>
      </Row>
    </Wrapper>
  );
};

const StyledTitle = styled(Title)`
  max-width: 100%;
  padding-bottom: 24px;
  font-size: 45px;
  line-height: 56px;
`;

const StyledImage = styled.img`
  margin-top: 48px;
  width: 100%;
  height: 383px;
  max-width: 620px;
  object-fit: cover;
  object-position: left;
  border-radius: 4px;
`;

export default SigningUp;
