import styled from 'styled-components';

import { Col } from '@/components/ui/flexbox';

const ModelAndDescriptionsWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  z-index: 1;
  justify-content: space-between;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    padding-right: 80px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

const ImageOuterWrapper = styled(Col)`
  order: 2;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 0;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  margin: auto;
  min-width: 160px;

  img:first-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-35%, -48%);
    z-index: -1;
  }

  img:not(:first-child) {
    width: 307px;
    margin-right: 85px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    img:first-child {
      width: 684px;
      height: 684px;
      transform: translate(-50%, -48%);
    }

    img:not(:first-child) {
      width: 255px;
      height: 645px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    order: 2;

    img:first-child {
      width: 350px;
      height: 350px;
      transform: translate(-55%, -48%);
    }

    img:not(:first-child) {
      width: 150px;
      height: 379px;
    }
  }
`;

const MobileImageWrapper = styled.div`
  order: 1;
  position: relative;
  margin: auto;
  min-width: 160px;

  img {
    width: 100%;
    height: 100%;
  }
`;

export { ModelAndDescriptionsWrapper, ImageOuterWrapper, ImageWrapper, MobileImageWrapper };
