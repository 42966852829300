import styled, { css } from 'styled-components';

export const Container = styled.div`
  .-sbd-widget {
    z-index: 99999;
    width: 48px;
    height: 48px;
    position: fixed;
    bottom: 10px;
    right: 20px;

    @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
      width: 60px;
      height: 60px;
      right: 8px;
      bottom: 8px;
      ${({ $show }) =>
        $show &&
        css`
          right: 0;
          bottom: 0;
          height: 100%;
        `};
    }
  }
`;

export const Panel = styled.div`
  ${({ $hide }) =>
    $hide &&
    css`
      opacity: 0;
      visibility: hidden;
    `};

  width: 360px;
  max-height: 624px;
  height: calc(100dvh - 150px);
  position: absolute;
  right: 0;
  bottom: 60px;
  background: white;
  overflow: hidden;
  border-radius: 24px;
  transition: opacity 0.3s;
  box-shadow: 0px 6px 17px 0px #1f222c12;
  z-index: 2;

  .sendbird-dropdown-portal .sendbird-observing-message-menu {
    & > .sendbird-dropdown__menu-backdrop {
      display: none;
    }
    & > ul > li {
      display: none;
    }
    & > ul > li:first-of-type {
      display: block;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    width: 100vw;
    bottom: 0;
    height: 90dvh;
    max-height: unset;
    border-radius: 12px 12px 0 0;
  }
  .sendbird-app__conversation-wrap {
    height: 100%;
  }

  .-sbd-header {
    position: relative;
    width: 100%;
    height: 84px;
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.primary.default};
    overflow: visible;
  }
`;
export const MenuList = styled.div`
  display: none;
`;
export const ChatHeader = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 64px;
  padding: 16px 24px;
  padding-right: 50px;
  background-color: ${(props) => props.theme.colors.primary.default};
  color: ${(props) => props.theme.colors.white};
  position: relative;
  p {
    font-family: ${(props) => props.theme.typography.family};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
`;
export const ChatContainer = styled.div`
  --sendbird-light-primary-300: ${(props) => props.theme.colors.primary[100]};
  --sendbird-light-ondark-01: ${(props) => props.theme.colors.grey[900]};
  --sendbird-light-primary-400: transparent;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 12px;
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    width: 100vw;
    bottom: 0;
    top: unset;
    height: 90dvh;
  }

  .sendbird-conversation {
    height: calc(100% - 64px);
    border: none;
    position: relative;
    & > .sendbird-conversation__messages {
      position: absolute;
      top: 0;
      height: calc(100% - 70px);
      width: 100%;
      padding: 0;

      & > .sendbird-conversation__scroll-container {
        & > .sendbird-conversation__messages-padding {
          & > .sendbird-msg-hoc {
            &:first-of-type {
              & > .sendbird-admin-message {
                display: block;
              }
            }
          }

          & > .sendbird-msg-hoc {
            font-family: ${(props) => props.theme.typography.family.body};
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            text-align: left;
            .sendbird-admin-message {
              display: none;
            }
            & > .sendbird-separator > .sendbird-separator__text .sendbird-label {
              font-family: ${(props) => props.theme.typography.family.body};
              color: ${(props) => props.theme.colors.grey[700]};
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              text-align: left;
            }

            & > div.sendbird-admin-message:first-of-type {
              & > .sendbird-admin-message__text {
                background-color: ${(props) => props.theme.colors.white};
              }
            }
            & > div.sendbird-admin-message {
              & > .sendbird-admin-message__text {
                font-family: ${(props) => props.theme.typography.family.body};
                color: ${(props) => props.theme.colors.grey[900]};
                background-color: ${(props) => props.theme.colors.neutral.default};
                border-radius: 12px;
                padding: 12px;
                font-size: 12px;
                font-weight: 500;
                line-height: 22px;
                text-align: center;
              }
            }
            &
              > .sendbird-message-hoc__message-content.sendbird-message-content
              > .sendbird-message-content__middle
              > .sendbird-message-content__middle__body-container {
              & > .sendbird-og-message-item-body {
                min-width: unset !important;
                max-width: 200px !important;

                & > .sendbird-label--body-1 {
                  font-weight: 500;
                  & > .sendbird-og-message-item-body__text-bubble {
                    font-family: ${(props) => props.theme.typography.family.body};
                    background: transparent;
                    padding: 0 !important;
                    &:hover {
                      background: transparent;
                    }
                    & > .sendbird-word > .sendbird-link-label > .sendbird-link-label__label {
                      font-weight: 500;
                      line-height: 22px;
                      font-family: ${(props) => props.theme.typography.family.body};
                      text-decoration: underline;
                    }
                  }
                }
              }

              & > .sendbird-message-content__middle__body-container__created-at {
                &
                  > .sendbird-message-content__middle__body-container__created-at__component-container
                  > .sendbird-message-status
                  > .sendbird-message-status__icon {
                  display: none;
                }
              }
            }

            & > .sendbird-message-hoc__message-content.sendbird-message-content.outgoing {
              &:hover {
                & > .sendbird-message-content__left {
                  & > .sendbird-message-content-menu.outgoing {
                    display: none;
                  }
                }
                &
                  > .sendbird-message-content__middle
                  .sendbird-message-content__middle__body-container
                  > .sendbird-message-content__middle__body-container__created-at {
                  display: flex !important;
                }
              }
              &
                > .sendbird-message-content__middle
                .sendbird-message-content__middle__body-container {
                display: flex;
                flex-direction: column-reverse;
                gap: 4px;
                background-color: ${(props) => props.theme.colors.primary[50]};
                border-radius: 12px;
                padding: 12px;
                max-width: 240px;

                & > .sendbird-message-content__middle__body-container__created-at {
                  position: unset;
                }
                & > .sendbird-label {
                  & > .sendbird-text-message-item-body.outgoing {
                    background-color: transparent;
                    font-family: ${(props) => props.theme.typography.family.body};
                    color: ${(props) => props.theme.colors.grey[900]};
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 22px;
                    text-align: left;
                    padding: 0;
                  }
                }
              }
            }
            & > .sendbird-message-hoc__message-content.sendbird-message-content.incoming {
              &:hover {
                & > .sendbird-message-content__right {
                  display: none;
                }
                &
                  > .sendbird-message-content__middle
                  .sendbird-message-content__middle__body-container
                  > .sendbird-message-content__middle__body-container__created-at {
                  display: flex !important;
                }
              }
              & > .sendbird-message-content__left > .sendbird-context-menu > div[role='button'] {
                pointer-events: none;
              }
              &
                > .sendbird-message-content__middle
                .sendbird-message-content__middle__body-container {
                display: flex;
                flex-direction: column;
                gap: 4px;
                background-color: ${(props) => props.theme.colors.neutral.default};
                border-radius: 12px;
                padding: 12px;

                max-width: 240px;

                & > .sendbird-message-content__middle__body-container__created-at {
                  position: unset;
                  justify-content: flex-end;
                }
                & > .sendbird-label {
                  & > .sendbird-text-message-item-body.incoming {
                    background-color: transparent;
                    font-family: ${(props) => props.theme.typography.family.body};
                    color: ${(props) => props.theme.colors.grey[900]};
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 22px;
                    text-align: left;
                    padding: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
    .sendbird-ui-header {
      display: none;
      width: 0;
      height: 0;
    }

    .sendbird-conversation__footer {
      position: absolute;
      bottom: 0;
      height: 70px;
      padding: 0px;
      & > .sendbird-message-input-wrapper > form {
        padding: 0px;
        & > .sendbird-message-input {
          display: flex;
          & > .sendbird-message-input-text-field {
            height: 70px;
            border: 0px;
            border-radius: unset;
            border-top: 1px solid ${(props) => props.theme.colors.grey[200]};
            box-shadow: none;
            outline: none;
            padding: 24px 32px 12px 12px;
            font-size: 16px;
            &::-webkit-scrollbar {
              width: 4px;
            }

            &::-webkit-scrollbar-thumb {
              background-color: ${(props) => props.theme.colors.primary[200]};
              outline: 1px solid ${(props) => props.theme.colors.primary[200]};
            }
          }
          & > .sendbird-message-input--attach {
            display: none;
            width: 0;
            height: 0px;
          }

          & > .sendbird-message-input--placeholder {
            top: 24px;
          }
          & > [data-testid='sendbird-message-input-send-button'] {
            right: 4px;
            bottom: 20px;
            & > .sendbird-iconbutton__inner > .sendbird-icon-send {
              display: flex;
              & > svg > path {
                fill: ${(props) => props.theme.colors.primary.default};
              }
            }
          }
        }
      }
    }
    ${({ $disabled }) =>
      $disabled &&
      css`
        .sendbird-conversation__footer > .sendbird-message-input-wrapper {
          border-top: 1px solid ${(props) => props.theme.colors.grey[200]};
          > .sendbird-message-input-wrapper__message-input {
            &:sendbird-message-input-text-field {
              display: none;
              visibility: hidden;
            }
            pointer-events: none;

            opacity: 0.4;
          }
        }
      `};
  }
`;

export const InfoBalloon = styled.div`
  ${({ $hide }) =>
    $hide &&
    css`
      opacity: 0;
      visibility: hidden;
    `};

  cursor: pointer;
  width: 220px;
  height: auto;
  padding: 12px 16px;
  background-color: ${(props) => props.theme.colors.primary[100]};
  color: ${(props) => props.theme.colors.grey.dark};
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 60px;
  transition: opacity 0.3s;
  p {
    font-family: ${(props) => props.theme.typography.family.body};
  }

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    right: 20px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${(props) => props.theme.colors.primary[100]} transparent transparent transparent;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    bottom: 8px;
    right: 70px;
    width: calc(100vw - 84px);
    min-width: 200px;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: -10px;
      margin-left: 5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent transparent
        ${(props) => props.theme.colors.primary[100]};
    }
  }
`;
export const ActionButton = styled.button`
  cursor: pointer;
  width: 48px;
  height: 48px;
  position: relative;
  background-color: ${(props) => props.theme.colors.primary.default};
  border-radius: 100px;
  box-shadow: 0 2px 32px 0 rgba(0, 0, 0, 0.15), 0 1px 6px 0 rgba(0, 0, 0, 0.09);
  background-image: url('/icons/ic_chat.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    width: 60px;
    height: 60px;
  }
  &:hover {
    box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.25), 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  }
  ${({ $isActive }) =>
    $isActive &&
    css`
      background-image: url('/icons/white_arrow_down.svg');
      box-shadow: 20px 20px 200px 200px rgba(0, 0, 0, 0.09);
      @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
        display: none;
      }
    `};
`;
export const CountMessages = styled.div`
  border: 1.5px solid ${(props) => props.theme.colors.white};
  position: absolute;
  right: 0;
  top: -5px;
  border-radius: 100px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.negative.default};
  color: ${(props) => props.theme.colors.white};
  font-size: 12px;
  text-align: right;
  overflow: visible;
`;

export const CloseButton = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    span {
      background-color: ${(props) => props.theme.colors.grey[900]};
    }
  }
  span {
    display: block;
    width: 14px;
    height: 2px;
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.white};
  }
`;
export const BackgroundCover = styled.button`
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.85);
  position: absolute;
  right: 0;
`;

export const ParagraphMessage = styled.p`
  font-family: ${(props) => props.theme.typography.family.body};
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;

  & > a {
    text-decoration: underline;
  }
`;
