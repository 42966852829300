import styled, { css } from 'styled-components';

const ImageQuoteInfo = ({ quote, name, clinicalSituation, hasVideo, centered }) => {
  return (
    <Wrapper $hasVideo={hasVideo} $centered={centered}>
      <Quote $smallTabletFont>{quote}</Quote>
      <Name>{name}</Name>
      <Label>{clinicalSituation}</Label>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colors.white};
  left: 48px;
  ${({ $centered }) =>
    $centered
      ? css`
          top: 50%;
          transform: translateY(-50%);
        `
      : css`
          bottom: 48px;
        `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    left: 40px;
    ${({ $centered }) =>
      !$centered &&
      css`
        bottom: 40px;
      `};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    left: unset;
    bottom: 0;
    padding: ${(props) => (props.$hasVideo ? '100px 24px 24px' : '24px')};
    position: relative;
  }
`;

const Quote = styled.div`
  font-family: ${(props) => props.theme.typography.family.title};
  font-weight: 600;
  font-size: 25px;
  max-width: 340px;
  width: 100%;
  line-height: 32px;
  margin-bottom: 16px;

  ${({ $smallTabletFont }) =>
    $smallTabletFont &&
    css`
      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        font-size: 20px;
        line-height: 24px;
        max-width: 309px;
      }
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 20px;
    line-height: 24px;
    max-width: unset;
  }
`;

const Name = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.9;
`;

const Label = styled.div`
  font-size: 16px;
  line-height: 24px;
  opacity: 0.9;
`;

export default ImageQuoteInfo;
