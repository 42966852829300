import dynamic from 'next/dynamic';

import Wrapper from './Wrapper';
import Title from './Title';
import Companies from './Companies';

const Marquee = dynamic(() => import('./StyledMarquee'), {
  loading: () => <span data-testid="marquee-loader" />,
  ssr: false,
});

const Container = dynamic(() => import('@/components/ui/flexbox/Container'), {
  loading: () => <span data-testid="container-loader" />,
  ssr: false,
});

const TrustedCompanies = ({ content, colored }) => {
  const { title, companies } = content;

  return (
    <Wrapper>
      <Title data-testid="title">{title}</Title>
      <Container large>
        <Marquee content={companies.data} colored={colored}>
          <Companies list={companies.data} />
        </Marquee>
      </Container>
    </Wrapper>
  );
};

export default TrustedCompanies;
