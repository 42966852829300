import { useContext } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/navigation';

import { Container } from '@/components/ui/flexbox';

import { Button } from '@swordhealth/ui-corporate';
import Logo from './Logo';
import { products } from '@/utils/products';
import ClientContext from '@/contexts/client.context';
import { ACTIONS, BUTTON_TYPES, trackButtonClicked } from '@/utils/track';
import { useInView } from 'react-intersection-observer';

export default function Bar({ selectProduct, navigation, portugueseHealthPlan = false }) {
  const { setProduct } = useContext(ClientContext);
  const router = useRouter();

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '80px',
  });

  return (
    <div ref={ref}>
      <Wrapper $white={!inView}>
        <Container large>
          <WrapperInner>
            <div>
              <Logo />
            </div>
            <Buttons>
              {!portugueseHealthPlan && (
                <Button
                  variant="secondary"
                  size="sm"
                  href={`${process.env.NEXT_PUBLIC_ONBOARDING_BASE_URL}`}
                  onClick={() => {
                    trackButtonClicked({
                      action: ACTIONS.REDIRECT_TO_SIGN_IN,
                      buttonLocation: 'header',
                      buttonText: 'Sign in here',
                      buttonType: BUTTON_TYPES.NAVIGATION,
                    });
                  }}
                >
                  Login
                </Button>
              )}
              <Button
                variant="primary"
                size="sm"
                onClick={() => {
                  if (!portugueseHealthPlan) {
                    selectProduct(products['PLATFORM']);
                    setProduct(products.PLATFORM);
                    trackButtonClicked({
                      action: ACTIONS.REDIRECT_TO_COVERAGE,
                      buttonType: BUTTON_TYPES.NAVIGATION,
                      buttonText: 'Get started',
                      destination: '/eligibility-check',
                      buttonLocation: 'bar',
                    });
                    router.push(`/eligibility-check`);
                  } else {
                    trackButtonClicked({
                      action: ACTIONS.REDIRECT_TO_FORM,
                      buttonType: BUTTON_TYPES.NAVIGATION,
                      buttonText: 'Get started',
                      destination: '/thrive/registo/formulario',
                      buttonLocation: 'bar',
                    });
                    router.push(`/thrive/registo/formulario`);
                  }
                }}
              >
                {navigation?.buttonLabel ? navigation.buttonLabel : 'Get started'}
              </Button>
            </Buttons>
          </WrapperInner>
        </Container>
      </Wrapper>
    </div>
  );
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: transparent;
  transition: all 0.3s ease;
  padding: 20px 0;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 10px 0;
  }

  ${(props) =>
    props.$white &&
    css`
      background-color: #f7f4f2;
      box-shadow: rgba(233, 220, 211, 0.4) 0 2px 10px 0;
    `};
`;

const WrapperInner = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    > *:nth-child(2) {
      display: none;
    }
    > *:first-child {
      padding: 9px 24px;
      height: 40px;
    }
  }
`;
