import { useMemo, useState } from 'react';
import Section from '@/components/ui/Section';
import { Container } from '@/components/ui/flexbox';
import { SectionHeader } from '@swordhealth/ui-corporate';

import {
  Header,
  CustomAccordion,
  CustomAccordionItem,
  StyledContent,
  CategoriesList,
  StyledCategory,
  StyledDropdown,
} from './styles';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';
import { FAQPageJsonLd } from 'next-seo';
import mapFaqToSeo from '@/utils/mapFaqToSeo';

const DEFAULT_CATEGORY = 'All';

const Faqs = ({ content }) => {
  const { title, upperLabel, description, accordion } = content;
  const [selectedCategory, setSelectedCategory] = useState(DEFAULT_CATEGORY);
  const [expandedItemIndex, setExpandedItemIndex] = useState(null);

  const categories = useMemo(() => {
    const uniqueCategories = accordion.reduce((acc, item) => {
      if (item?.category && !acc.includes(item?.category)) {
        acc.push(item?.category);
      }
      return acc;
    }, []);

    return uniqueCategories.length === 0
      ? []
      : [DEFAULT_CATEGORY, ...uniqueCategories].map((category) => ({
          value: category,
          label: category,
        }));
  }, [accordion]);

  const filteredFaqs = useMemo(() => {
    return accordion.filter(
      (item) => item?.category === selectedCategory || selectedCategory === DEFAULT_CATEGORY,
    );
  }, [accordion, selectedCategory]);

  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);

  if (!content?.isActive) return null;

  const handleAccordionToggle = (index) => {
    setExpandedItemIndex((currentIndex) => (currentIndex === index ? null : index));
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setExpandedItemIndex(null);
  };

  return (
    <Section id="faqs" md="136" xs="64">
      <FAQPageJsonLd useAppDir={true} mainEntity={mapFaqToSeo(accordion)} />
      <Container small>
        <Header>
          <SectionHeader
            description={description}
            label={upperLabel}
            title={title}
            titleSize="2xl"
            xAlign="center"
          />
        </Header>

        <StyledContent>
          {categories.length > 0 &&
            (!isMobile ? (
              <CategoriesList>
                {categories.map((category) => (
                  <li key={category.key}>
                    <StyledCategory
                      variant="text"
                      onClick={() => handleCategoryChange(category.value)}
                      $selectedCategory={selectedCategory === category.value}
                    >
                      {category.label}
                    </StyledCategory>
                  </li>
                ))}
              </CategoriesList>
            ) : (
              <StyledDropdown
                placeholder={'Select a category'}
                options={categories}
                defaultValue={selectedCategory}
                onChange={handleCategoryChange}
              />
            ))}
          <CustomAccordion>
            {filteredFaqs.map((faq, index) => (
              <CustomAccordionItem
                id={`faq-item-${index}`}
                key={faq.id || index}
                title={faq.title}
                expanded={index === expandedItemIndex}
                handleClick={() => handleAccordionToggle(index)}
              >
                {faq.description}
              </CustomAccordionItem>
            ))}
          </CustomAccordion>
        </StyledContent>
      </Container>
    </Section>
  );
};

export default Faqs;
