const Loading = ({ visible }) => {
  if (!visible) return null;
  return (
    <>
      <div class="-sbd-spinner">
        <div class="-sbd-spinner-wrapper">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="-sbd-spinner">
        <div class="-sbd-spinner-wrapper">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </>
  );
};
export default Loading;
