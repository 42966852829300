import { SectionHeader, LogoGrid } from '@swordhealth/ui-corporate';
import { StyledSection, StyledContainer, Inner } from './styles';

const ClinicalGrade = ({ content }) => {
  const {
    titles: { upperLabel, title, description },
    companies,
  } = content;

  const filteredCompanies = companies.data.map((company) => company.attributes);

  return (
    <StyledSection colored>
      <Inner as="div">
        <StyledContainer>
          <SectionHeader
            description={description}
            label={upperLabel}
            title={title}
            titleSize="2xl"
            xAlign="center"
          />
          <LogoGrid logoList={filteredCompanies} className={'logo-grid-clinical-grade'} />
        </StyledContainer>
      </Inner>
    </StyledSection>
  );
};

export default ClinicalGrade;
