import styled from 'styled-components';
import { Container } from '@swordhealth/ui-corporate';
import Section from '@/components/ui/Section';

export const StyledSection = styled(Section)`
  background: white;
  clip-path: circle(farthest-side at 50% -10000px) !important;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    clip-path: circle(farthest-side at 50% -4000px) !important;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    clip-path: circle(farthest-side at 50% -2500px) !important;
  }
`;

export const Inner = styled(Section)`
  background: ${(props) => props.theme.colors.neutral[200]};
  clip-path: circle(farthest-side at 50% -10000px);

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    clip-path: circle(farthest-side at 50% -4000px);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    clip-path: circle(farthest-side at 50% -2500px);
  }
`;

export const StyledContainer = styled(Container)`
  justify-content: center;
  --grid-gutter: 64px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    --grid-gutter: 40px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    --grid-gutter: 32px;
  }
`;
