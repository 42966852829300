import styled from 'styled-components';

import { H2, Label } from '@/components/ui/Title';

export const OuterWrapper = styled.div`
  & ${H2} {
    max-width: 727px;

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      max-width: 535px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      max-width: 100%;
    }
  }

  ${Label} {
    max-width: 624px;
    width: 100%;

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      max-width: 455px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      max-width: 100%;
    }
  }
`;

export default OuterWrapper;
