import Image from 'next/image';
import { useState } from 'react';
import styled from 'styled-components';
import { Container, Heading, Paragraph, RichText } from '@swordhealth/ui-corporate';

import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';
import Section from '@/components/ui/Section';
import { Col, Row } from '@/components/ui/flexbox';

import PainDescriptions from './Descriptions';
import PainPoints from './PainPoints';
import {
  ImageOuterWrapper,
  ImageWrapper,
  MobileImageWrapper,
  ModelAndDescriptionsWrapper,
} from './ModelImage';
import { MarkdownRichText } from '@/app/_components/shared';

const InnerWrapper = styled(Row)`
  align-items: center;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-left: 0;
  }
`;

const DescriptionWrapper = styled(Col)`
  align-items: center;
  justify-content: center;
  order: 1;

  h2 {
    padding-bottom: 16px;
  }

  p {
    max-width: 220px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) and (max-width: ${(props) =>
      props.theme.breakpoints.max_md}px) {
    padding-bottom: 64px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    h2 {
      max-width: 330px;
    }

    p {
      max-width: 220px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    order: 2;

    h2 {
      padding-bottom: ${(props) => props.theme.spacings.md};
    }
    p {
      font-weight: 500;
      font-size: ${(props) => props.theme.font.body.md};
      line-height: ${(props) => props.theme.font.body.lineHeight.md};
      max-width: 100%;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    order: 1;
    padding-left: 0;
    padding-bottom: 64px;
    text-align: center;

    h2 {
      font-size: 22px;
      line-height: 26px;
      padding-bottom: ${(props) => props.theme.spacings.sm};
    }
  }
`;

const Wrapper = styled.div`
  overflow: hidden;
  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    padding-bottom: 60px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-bottom: 30px;
  }
`;

export default function BodyParts({ title, subtitle, content }) {
  const [selectedPain, selectPain] = useState(1);
  const id = content?.id;
  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);

  return (
    <Section>
      <Wrapper>
        <Container>
          <InnerWrapper>
            <DescriptionWrapper xs={12} md={12} lg={4}>
              <Heading
                id={`what-we-treat-title-${id}`}
                data-testid="what-we-treat-title"
                as="h2"
                size="2xl"
              >
                <MarkdownRichText elements={['strong', 'b', 'i', 'u']}>{title}</MarkdownRichText>
              </Heading>
              <RichText
                as={Paragraph}
                id={`what-we-treat-subtitle-${id}`}
                data-testid="what-we-treat-subtitle"
              >
                {subtitle}
              </RichText>
            </DescriptionWrapper>

            <ImageOuterWrapper xs={12} md={12} lg={8}>
              <ModelAndDescriptionsWrapper>
                {isMobile ? (
                  <MobileImageWrapper>
                    <Image
                      id={`what-we-treat-image-${id}`}
                      src="/assets/pain-model-with-points.png"
                      alt="What We Treat"
                      width={696}
                      height={500}
                    />
                  </MobileImageWrapper>
                ) : (
                  <>
                    <ImageWrapper>
                      <Image
                        id={`what-we-treat-shape-${id}`}
                        alt="background shape"
                        src="/assets/shapes/ellipse.png"
                        width={699}
                        height={699}
                      />
                      <Image
                        id={`what-we-treat-image-${id}`}
                        src="/assets/pain-model.png"
                        alt="What We Treat"
                        className="pains-model"
                        width={307}
                        height={746}
                        sizes="(max-width: 767px) 100vw, (max-width: 991px) 50vw, 300px"
                      />
                      <PainPoints id={id} selectedPain={selectedPain} selectPain={selectPain} />
                    </ImageWrapper>
                  </>
                )}
                <PainDescriptions
                  id={id}
                  content={content?.bodyAreas}
                  selectedPain={selectedPain}
                  selectPain={selectPain}
                />
              </ModelAndDescriptionsWrapper>
            </ImageOuterWrapper>
          </InnerWrapper>
        </Container>
      </Wrapper>
    </Section>
  );
}
