import styled from 'styled-components';

import { getFootnoteId } from '@/app/components/platform/Footer/Footnotes/Footnotes';
import useReducedMotion from '@/utils/useReducedMotion';

function scrollToSection({ sectionId, isReduced }) {
  const id = getFootnoteId(sectionId);

  const section = document.getElementById(id);

  if (!section) return;

  section.scrollIntoView({ behavior: isReduced ? 'instant' : 'smooth' });
}

const Ref = ({ id, children, className }) => {
  const isReduced = useReducedMotion();

  return (
    <button
      type="button"
      className={className}
      onClick={() => scrollToSection({ sectionId: id, isReduced })}
    >
      {children}
    </button>
  );
};

const FooterReference = styled(Ref)`
  color: ${(props) => props.theme.colors.primary.default};
  font-size: 80%;
  vertical-align: super;
  margin-left: 4px;
  padding-right: 4px;
  cursor: pointer;
`;

export default FooterReference;
