import { useEffect, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import { v4 } from 'uuid';

import Channel from './Channel';

const coveredClients = [
  'medmutual',
  'davita',
  'gravie',
  'avmedmdc',
  'mcdlicensees',
  'fmcna',
  'phillips66',
  'ibx',
  'cova',
  'tlc',
  'nucor',
  'hft',
  'indianalaborers',
  'schneider',
  'dhl',
  'wood',
  'ohioteamsters',
  'netsmart',
  'gestamp',
  'thomsonreuters',
  'ufcwnational',
  'statefarm',
  'sysco',
  'sedgwick',
  'ohiolaborers',
];

const Chat = ({ visible = true, unit, client, institutionKey }) => {
  const searchParams = useSearchParams();
  const fingerprint = searchParams.get('fingerprint');
  const isOpen = searchParams.get('chat_open') === 'true';
  const [userId, setUser] = useState();

  const available =
    client == null ||
    coveredClients.some((item) => item.toLocaleLowerCase() === client.toLocaleLowerCase());

  useEffect(() => {
    let userid = `LP_${v4()}`;
    if (fingerprint) {
      userid = `LP_${fingerprint}`;
    }
    setUser(userid);
  }, []);

  return available && visible ? (
    <Channel
      userId={userId}
      unit={unit?.key || unit}
      client={institutionKey || client}
      isOpen={isOpen}
    />
  ) : null;
};

export default Chat;
