import styled from 'styled-components';

import QuoteIcon from '@/public/icons/quote.svg';

const Quote = ({ id, quote, author, authorRole }) => {
  return (
    <Wrapper>
      <QuoteIcon id={`testimonials-quote-icon-${id}`} />
      <QuoteText id={`testimonials-quote-text-${id}`}>{quote}</QuoteText>
      <Author id={`testimonials-author-${id}`}>{author}</Author>
      {authorRole && <AuthorRole id={`testimonials-author-role-${id}`}>{authorRole}</AuthorRole>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 64px;
  flex: 1;

  svg {
    width: 27px;
    min-height: 20px;
    height: 20px;
    margin-bottom: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 40px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 24px;

    svg {
      width: 21px;
      height: 16px;
      margin-bottom: 16px;
    }
  }
`;

const QuoteText = styled.div`
  font-family: ${(props) => props.theme.typography.family.title};
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

const Author = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-top: 24px;
  color: ${(props) => props.theme.colors.grey[800]};

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 16px;
  }
`;

const AuthorRole = styled.div`
  font-family: ${(props) => props.theme.typography.family.body};
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: ${(props) => props.theme.colors.grey[800]};
`;

export default Quote;
