import styled from 'styled-components';

const Info = styled.div`
  max-width: 470px;
  width: 100%;
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: unset;
    width: unset;
    padding-top: 24px;
  }
`;

export default Info;
