import { useState, useRef } from 'react';
import Image from 'next/image';
import Slider from 'react-slick';

import { Container } from '@/components/ui/flexbox';
import theme from '@/utils/styles-variables';
import getImgSrcWorkaround from '@/utils/images';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';

import VideoPlayerModal, { PlayButton } from '@/components/shared/VideoPlayerModal';

import CustomCol from './CustomCol';
import CustomRow from './CustomRow';
import Header from './Header';
import Info from './Info';
import InfoWrapper from './InfoWrapper';
import MainWrapper from './MainWrapper';
import OuterWrapper from './OuterWrapper';
import ImageQuoteInfo from './ImageQuoteInfo';
import SliderWrapper from './SliderWrapper';
import Slide from './Slide';
import SliderContainer from './SliderContainer';
import SliderArrows from './SliderArrows';
import ImageGradient from './ImageGradient';

const SplitImageWithMetrics = ({ content, withRefs, withPaddingBottom = false }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState();
  const sliderRef = useRef();

  const isTablet = useBetterMediaQuery(
    `(min-width: ${theme.breakpoints.min_md}px) and (max-width: ${theme.breakpoints.max_md}px)`,
  );

  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);

  const { label, title, subTitle, linkHref, linkLabel, metrics, slider } = content;

  const REF = withRefs ? [1, 2, 3] : [];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <OuterWrapper $withPaddingBottom={withPaddingBottom}>
      <Container>
        <Header
          label={label}
          title={title}
          subTitle={subTitle}
          linkHref={linkHref}
          linkLabel={linkLabel}
        />
        {videoUrl && metrics && (
          <VideoPlayerModal
            modalIsOpen={modalIsOpen}
            closeModal={() => setModalIsOpen(false)}
            videoURL={videoUrl}
          />
        )}
      </Container>
      <SliderContainer>
        <CustomRow $mgTop={!metrics}>
          <CustomCol lg={12}>
            <SliderWrapper $fullWidth={!metrics}>
              {metrics && slider.length > 1 && !isTablet && !isMobile && (
                <SliderArrows sliderRef={sliderRef} top />
              )}

              <MainWrapper $hasImage $showDots $fullWidth={!metrics}>
                <Slider ref={sliderRef} {...settings}>
                  {slider.map((slide) => (
                    <Slide key={`slide-${slide.id}`}>
                      <MainWrapper $hasImage $fullWidth={!metrics}>
                        {slide.videoUrl && (
                          <PlayButton
                            onClick={() => {
                              setVideoUrl(slide.videoUrl);
                              setModalIsOpen(true);
                            }}
                          />
                        )}
                        <Image
                          className="main-image"
                          src={getImgSrcWorkaround(
                            slide?.picture?.url ?? slide?.picture?.data?.attributes?.url,
                          )}
                          alt={
                            slide?.picture?.alternativeText ??
                            slide?.picture?.data?.attributes?.alternativeText
                          }
                          fill
                          sizes="(max-width: 767px) 100vw,(max-width: 991px) 440px, 859px"
                        />
                        {!metrics && <ImageGradient />}
                        <ImageQuoteInfo
                          quote={slide.quote}
                          name={slide.quoteName}
                          clinicalSituation={slide.quoteLabel}
                          hasVideo={slide.videoUrl}
                          centered={!metrics}
                        />
                      </MainWrapper>
                    </Slide>
                  ))}
                </Slider>
              </MainWrapper>

              {((slider.length > 1 && isTablet) ||
                (!metrics && slider.length > 1 && !isMobile)) && (
                <SliderArrows sliderRef={sliderRef} />
              )}
            </SliderWrapper>

            {metrics && (
              <InfoWrapper>
                {metrics?.map((customer, index) => (
                  <Info
                    key={index}
                    numbers={customer.numbers}
                    label={customer.label}
                    reference={REF[index]}
                  />
                ))}
              </InfoWrapper>
            )}
          </CustomCol>
        </CustomRow>
      </SliderContainer>
    </OuterWrapper>
  );
};

export default SplitImageWithMetrics;
