import { Container, Row, Col } from '@/components/ui/flexbox';

import Item from './Links';
import Legal from './Legal';
import { Section, InnerWrapper, LogoWrapper, MailCol, Disclaimer } from './styles';

import Logo from '@/public/sword__colored.svg';

const FOOTER = {
  en: {
    disclaimer:
      ' Sword Health, Inc. does not provide healthcare services. Sword Health Professionals provides clinical services through a group of independently owned professional practices consisting of Sword Health Care Providers, P.A., Sword Health Care Providers of NJ, P.C., and Sword Health Care Physical Therapy Providers of CA, P.C.',
    supportText: 'Need help? Contact us at ',
    supportEmail: 'help@swordhealth.com',
    rightsReserved: 'Sword Health, Inc. All Rights Reserved',
  },
  pt: {
    disclaimer:
      ' Sword Health, Inc. does not provide healthcare services. Sword Health Professionals provides clinical services through a group of independently owned professional practices consisting of Sword Health Care Providers, P.A., Sword Health Care Providers of NJ, P.C., and Sword Health Care Physical Therapy Providers of CA, P.C.',
    supportText: 'Need help? Contact us at ',
    supportEmail: 'help@swordhealth.com',
    rightsReserved: 'Sword Health, Inc. All Rights Reserved',
  },
};

const Footer = ({ email, extraPadding = false, isPortugueseLP = false }) => {
  const locale = isPortugueseLP ? 'pt' : 'en';

  const helpContent = {
    supportText: FOOTER[locale].supportText,
    href: email?.href || FOOTER[locale].supportEmail,
    label: email?.label || FOOTER[locale].supportEmail,
  };

  return (
    <Section as="footer">
      <Container large>
        <InnerWrapper $extraPadding={extraPadding}>
          <Row>
            <Col lg={3}>
              <LogoWrapper>
                <Logo />
              </LogoWrapper>
            </Col>
          </Row>
          <Row>
            <MailCol lg={3} xs={12}>
              <p>{helpContent.supportText}</p>
              <Item href={`mailto:${helpContent.href}`} label={helpContent.label} />
            </MailCol>
            <Col lg={3} xs={12}>
              <Legal locale={locale} />
            </Col>
            <Col lg={6} xs={12}>
              <Disclaimer>
                {FOOTER[locale].disclaimer}
                <p>
                  © {new Date().getFullYear()} {FOOTER[locale].rightsReserved}
                </p>
              </Disclaimer>
            </Col>
          </Row>
        </InnerWrapper>
      </Container>
    </Section>
  );
};

export default Footer;
