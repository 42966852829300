import styled from 'styled-components';

import { Container } from '@/components/ui/flexbox';
import { UpperText, Label } from '@/components/ui/Title';

const Wrapper = styled(Container)`
  ${UpperText} {
    padding-bottom: 24px;
  }

  ${Label} {
    padding-bottom: 24px;
  }
`;

export default Wrapper;
