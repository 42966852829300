import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.8fr;
  column-gap: 80px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    grid-template-columns: 1fr;
    row-gap: 24px;
  }
`;
export const Content = styled.div`
  display: grid;
  row-gap: 24px;
  place-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    row-gap: 16px;
  }
`;

export const ImageElement = styled.figure`
  position: relative;
  aspect-ratio: 5/4;

  img {
    object-fit: contain;
    border-radius: 16px;
  }
`;
