import { useCallback, useRef, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useRouter, useSearchParams } from 'next/navigation';

import { baseStyles } from '@/app/_components/core/CoverageForm/CompaniesDropdown/dropdown.styles';

import { Dropdown } from '@/app/_components/core/CoverageForm/Dropdown';
import { CompaniesDropdown } from '@/app/_components/core/CoverageForm/CompaniesDropdown';
import { Button } from '@swordhealth/ui-corporate';

import { getClient, searchClient } from '@/services/client.service';
import { compareDates } from '@/utils/dates';
import { debounce } from '@/utils/debounce';
import getCountry from '@/utils/get-country';

import { createGuardaLink, getOnboardingURL } from '@/utils/onboarding-urls';

import {
  ACTIONS,
  BUTTON_TYPES,
  trackButtonClicked,
  trackClientEligibilityChecked,
} from '@/utils/track';

const SUBMIT_LABEL = 'Get Started';

import { Form, Field, FormContainer } from './styles';
import EnrollButton from '@/app/_components/core/EnrollButton';
import { products } from '@/utils/products';

export default function FormComponent({ product, client, enrollButtonId }) {
  const searchParams = useSearchParams();
  const [isNotCovered, setIsNotCovered] = useState(false);
  const [countries, setCountries] = useState(null);
  const [focusSelect, setFocusSelect] = useState(false);
  const selectClient = useRef(null);
  const router = useRouter();

  useEffect(() => {
    if (focusSelect) {
      selectClient?.current?.focus();
      setFocusSelect(false);
    }
  }, [focusSelect]);

  const formik = useFormik({
    initialValues: {
      client: client.clientKey ? client : null,
      country: null,
    },
    onSubmit: async (values, actions) => {
      if (!values.client || ((client.countries || countries) && !values.country)) {
        selectClient?.current?.focus();
        return;
      }

      if (values.client && !isNotCovered) {
        let fetchedClient = client;
        if (!client.clientKey) {
          fetchedClient = await getClient({
            client: values.client.key,
            productKey: client?.product?.key,
          });
        }

        if (fetchedClient && compareDates(fetchedClient?.launchDate)) {
          return router.push(`/waiting-list?client=${fetchedClient.clientKey}`);
        }

        const url = client?.redirectToApp
          ? createGuardaLink({
              clientKey: fetchedClient.clientKey,
              programType: product?.param,
              serviceApp: product?.app,
            })
          : getOnboardingURL({
              client: fetchedClient,
              country: values?.country,
              programType: client?.product?.param,
              query: Object.fromEntries(searchParams),
            });

        trackClientEligibilityChecked({
          clientExists: true,
          clientCountry: values?.country?.key,
          clientId: fetchedClient.clientKey,
          clientName: fetchedClient.name,
        });

        trackButtonClicked({
          action: client?.redirectToApp ? ACTIONS.REDIRECT_TO_APP : ACTIONS.REDIRECT_TO_ONBOARDING,
          buttonType: BUTTON_TYPES.NAVIGATION,
          buttonText: SUBMIT_LABEL,
          destination: url,
          buttonLocation: 'form',
        });
        window.location.assign(url);
        return;
      }

      trackClientEligibilityChecked({
        clientExists: false,
        clientCountry: null,
        clientId: values.client.key,
        clientName: values.client.label,
      });

      setIsNotCovered(true);

      actions.resetForm({
        values: {
          client: null,
          country: null,
        },
      });

      setCountries(null);
      router.push(`/not-covered?client=${values.client.key}&program=${product?.key}`);
    },
  });

  const validateClient = useCallback(
    async (selectedClient, formik) => {
      formik.setStatus({ validating: true });
      setCountries(null);

      try {
        const fetchedClient = await getClient({
          client: selectedClient.key,
          productKey: product?.key,
        });

        if (fetchedClient?.countries?.length > 1) {
          setCountries(fetchedClient.countries);
        }
      } catch {
        setIsNotCovered(true);
      } finally {
        formik.setStatus({ validating: false });
      }
    },
    [product],
  );

  const loadOptions = useCallback(
    (query, callback) => {
      searchClient({ query, ...product }).then((results) => {
        if (results.length === 0) {
          callback([{ key: query, label: query }]);
        } else {
          callback(
            results.map((result) => {
              return {
                key: result.clientKey || '',
                label: result.name,
              };
            }),
          );
        }
      });
    },
    [product],
  );

  const handleReset = () => {
    formik.resetForm();
    setCountries(null);
  };

  if (client?.clientKey && (!client?.countries || client?.countries?.length < 2)) {
    return (
      <FormContainer>
        <EnrollButton
          id={enrollButtonId}
          client={client}
          product={products[client?.product?.key?.toUpperCase()]}
          isActive={client.isActive}
          buttonLocation="form"
          buttonLabel={SUBMIT_LABEL}
          triage={client?.hasTriage}
          redirectToApp={client?.redirectToApp}
        />
      </FormContainer>
    );
  }

  if (client?.clientKey) {
    return (
      <Form data-testid="onboarding-form" onSubmit={formik.handleSubmit}>
        <Field>
          <Dropdown
            name="country"
            placeholder="Select your region/country"
            options={client.countries.map((country) => getCountry(country.country))}
            noOptionsMessage={() => 'No results available'}
            value={formik.values.country}
            onChange={(value) => {
              formik.setFieldValue('country', value);
            }}
            customBaseStyles={baseStyles}
          />
        </Field>
        <Button
          id={enrollButtonId}
          type="submit"
          loading={formik.status?.validating || formik.isSubmitting}
          disabled={formik.status?.validating || formik.isSubmitting}
          small
        >
          {SUBMIT_LABEL}
        </Button>
      </Form>
    );
  }

  return (
    <Form data-testid="onboarding-form" onSubmit={formik.handleSubmit}>
      <Field>
        <CompaniesDropdown
          innerRef={selectClient}
          name="companies"
          customBaseStyles={baseStyles}
          className="companiesDropdown"
          placeholder="Enter your employer or health plan"
          loadOptions={debounce(loadOptions)}
          noOptionsMessage={(e) => (e.inputValue ? 'No results available' : null)}
          value={formik.values.client}
          onChange={(value) => {
            formik.setValues({ client: value, country: null });
            validateClient(value, formik);
          }}
          error={formik.errors.client && formik.touched.client}
          errorLabel={'Not covered'}
          onFocus={() => {
            if (formik.values.client !== null) {
              setCountries(null);
              formik.setValues({ client: null, country: null });
            }
          }}
          handleReset={handleReset}
        />
      </Field>

      {countries && (
        <Field>
          <Dropdown
            name="country"
            placeholder="Select your region/country"
            options={countries.map((country) => getCountry(country.country))}
            noOptionsMessage={() => 'No results available'}
            value={formik.values.country}
            onChange={(value) => {
              formik.setFieldValue('country', value);
            }}
            customBaseStyles={baseStyles}
          />
        </Field>
      )}

      <Button
        id={enrollButtonId}
        type="submit"
        loading={formik.status?.validating || formik.isSubmitting}
        disabled={formik.status?.validating || formik.isSubmitting}
        small
      >
        {SUBMIT_LABEL}
      </Button>
    </Form>
  );
}
