import Section from '@/components/ui/Section';
import styled from 'styled-components';

export const StyledCTA = styled(Section)`
  text-align: center;
  padding: 88px 0 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 64px 0 0 0;
  }

  h2 {
    padding-bottom: 24px;

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      font-size: 48px;
      line-height: 56px;
      letter-spacing: -1px;
      padding-bottom: 16px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      font-size: 28px;
      line-height: 32px;
      letter-spacing: -0.5px;
    }
  }
`;

export const Gradient = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url('/assets/backgrounds/bottom-cta-gradient-background.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  z-index: 1;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    background-image: none;
  }
`;
