import styled from 'styled-components';

const Description = styled.div`
  font-size: 16px;
  line-height: 150%;
  max-width: 296px;
  margin-top: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    line-height: 28px;
    max-width: 100%;
  }
`;

export default Description;
