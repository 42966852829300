import styled from 'styled-components';
import { Label } from '@/components/ui/Title';
import { Container } from '@/components/ui/flexbox';

export const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  margin: 0 auto;
  max-width: 640px;
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    align-items: flex-start;
    gap: 16px;
    margin: 0;
    text-align: left;
  }
`;

export const ExtraLabel = styled.div`
  font-family: ${(props) => props.theme.typography.family.title};
  padding-top: 40px;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    text-align: left;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: 24px;
  }
`;

export const CustomLabel = styled(Label)`
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  font-style: normal;
  padding-bottom: 0;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 16px;
    line-height: 28px;
  }
`;

export const ListWrapper = styled(Container)`
  overflow: hidden;
  max-width: 100%;
  margin-top: 80px;

  @media (max-width: ${({ theme }) => theme.breakpoints.max_md}px) {
    margin-top: 40px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.max_xs}px) {
    margin-top: 24px;
  }

  .embla__container {
    margin: 0 -12px;
    align-items: center;
    display: flex;
    justify-content: center;
    touch-action: pan-y;
  }

  .embla__slide {
    flex: 0 0 auto;
    padding: 0 12px;
    width: 264px;

    @media (max-width: ${({ theme }) => theme.breakpoints.max_md}px) {
      width: 212px;
      padding-left: 6px;
      padding-right: 6px;
    }
  }

  &.scrollable {
    cursor: grab;

    .embla__container {
      justify-content: flex-start;
    }
  }
`;
