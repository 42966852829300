import { useContext, useMemo, useCallback } from 'react';
import { useInView } from 'react-intersection-observer';
import moment from 'moment';

import EnrollButton from '@/app/components/core/EnrollButton';
import { MarkdownRichText } from '@/app/components/shared';
import { createSignInURL } from '@/utils/onboarding-urls';
import ClientContext from '@/contexts/client.context';
import { Wrapper, Label, FormWrapper, FixedWrapper, EnrollButtonWrapper } from './styles';
import { Heading, Paragraph, RichText } from '@swordhealth/ui-corporate';
import { Logos } from '@/app/components/shared/Logos/Logos';
import { products } from '@/utils/products';
import FormComponent from '@/components/platform/Form/Form';
import { ACTIONS, BUTTON_TYPES, trackButtonClicked } from '@/utils/track';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';
import Portal from '@/utils/create-portal';
import { formattedTitle } from '@/utils/formatted-title';

const ONBOARDING_BASE_URL =
  process.env.NEXT_PUBLIC_ONBOARDING_BASE_URL ?? 'https://onboarding.swordhealth.com/';
const BUTTON_LABEL = 'Sign in here';

const HeaderContent = ({
  content: { title, subtitle, eligibilityClaim, button },
  logo,
  showSwordLogo = true,
  userName,
}) => {
  const { client } = useContext(ClientContext);
  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);
  const [ref, inView, entry] = useInView({
    threshold: 1,
    initialInView: true,
    rootMargin: '-10px',
  });

  const hasLaunched = useMemo(
    () => !client.launchDate || moment(client.launchDate).isSameOrBefore(moment()),
    [client.launchDate],
  );

  const signInURL = useMemo(
    () =>
      createSignInURL(
        ONBOARDING_BASE_URL,
        {
          unit: client?.product?.key === 'thrive' ? 'dpt' : client?.product?.key,
          program_type: client?.product?.param,
          hide_get_started: true,
          client_ref: client?.institutionKey ?? client.clientKey,
        },
        client?.country?.data?.attributes?.ISOCode.toUpperCase() ?? 'US',
      ),
    [client],
  );

  const handleSignInClick = useCallback(() => {
    trackButtonClicked({
      action: ACTIONS.REDIRECT_TO_SIGN_IN,
      buttonLocation: 'header',
      buttonText: BUTTON_LABEL,
      buttonType: BUTTON_TYPES.NAVIGATION,
      destination: signInURL,
      page_title: '',
    });
  }, [signInURL]);

  const enrollButton = (isFixed = false) => (
    <EnrollButton
      id={isFixed ? 'fixed_header_button' : 'header_button'}
      isActive={client.isActive}
      client={client}
      buttonLabel={button?.label}
      comingSoonHref={client?.comingSoonHref}
      redirectToApp={client?.redirectToApp}
      buttonApp={{ label: button?.label, href: button?.href }}
      triage={client?.hasTriage}
      product={products[client?.product?.key?.toUpperCase()]}
      buttonLocation="header"
    />
  );

  const shouldShowForm =
    !client.clientKey ||
    (!client?.redirectToApp && (!client?.countries || client?.countries?.length >= 2));
  const shouldShowSignIn =
    client.isActive &&
    hasLaunched &&
    client.clientKey &&
    !client.redirectToApp &&
    client.countries.length < 2 &&
    !client?.loginOnboarding;
  const shouldShowFixedButton =
    isMobile && !inView && !!client.clientKey && entry?.boundingClientRect?.top < 100;

  return (
    <Wrapper>
      <Logos logos={logo ?? []} showSwordLogo={showSwordLogo} headerContent={true} />

      <Heading id="hero-title" variant="2" as="h1">
        {formattedTitle({ title, userName })}
      </Heading>

      {subtitle && <Paragraph id="hero-subtitle">{subtitle}</Paragraph>}

      {shouldShowForm ? (
        <FormWrapper>
          <FormComponent
            client={client}
            product={client.product}
            enrollButtonId="hero-form-button"
          />
        </FormWrapper>
      ) : (
        <EnrollButtonWrapper ref={ref}>{enrollButton()}</EnrollButtonWrapper>
      )}

      <Portal portalId="sticky-enroll-button-portal">
        <FixedWrapper $visible={shouldShowFixedButton}>
          <EnrollButtonWrapper>{enrollButton(true)}</EnrollButtonWrapper>
        </FixedWrapper>
      </Portal>

      {shouldShowSignIn && (
        <RichText as={Paragraph}>
          Already have an account?{' '}
          <a href={signInURL} onClick={handleSignInClick}>
            {BUTTON_LABEL}
          </a>
        </RichText>
      )}

      {eligibilityClaim && (
        <Label id="hero-eligibility-claim">
          <MarkdownRichText elements={['p', 'strong', 'em', 'a', 'u']}>
            {eligibilityClaim}
          </MarkdownRichText>
        </Label>
      )}
    </Wrapper>
  );
};

export default HeaderContent;
