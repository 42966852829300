import { useState } from 'react';
import Image from 'next/image';

import Section from '@/components/ui/Section';
import { Container } from '@/components/ui/flexbox';

import Card from './Card';

import getImgSrcWorkaround, { getImgData } from '@/utils/images';

import useBetterMediaQuery from '@/utils/useBetterMediaQuery';

import theme from '@/utils/styles-variables';

import { CardList, DesktopImageBox, ImageWrapper, Wrapper, TitleWrapper } from './styles';
import { Heading, Paragraph } from '@swordhealth/ui-corporate';

const Slider = ({ content, reverse, withNumber, name }) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_md}px)`);

  if (!content) return null;

  const { title, description, imgAccordion, id } = content;

  const image = getImgData(imgAccordion[slideIndex].image);

  return (
    <Section>
      <Container large>
        <TitleWrapper reverse={reverse}>
          <Heading id={`slider-title-${id}`} size="2xl" as="h2" style={{ marginBottom: '32px' }}>
            {title}
          </Heading>
          {description && <Paragraph id={`slider-description-${id}`}>{description}</Paragraph>}
        </TitleWrapper>
        <Wrapper reverse={reverse}>
          <CardList>
            {imgAccordion.map((item, index) => (
              <Card
                key={`${name}-slider-item-${item.id}`}
                number={withNumber ? index + 1 : null}
                content={item}
                onClick={() => setSlideIndex(index)}
                isActive={index === slideIndex}
                isMobile={isMobile}
              />
            ))}
          </CardList>

          <DesktopImageBox>
            <ImageWrapper>
              <Image
                alt={image?.alternativeText || ''}
                src={getImgSrcWorkaround(image?.url)}
                fill
                sizes="(max-width: 767px) 100vw, 60vw"
              />
            </ImageWrapper>
          </DesktopImageBox>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default Slider;
