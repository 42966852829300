import { Container } from '@/components/ui/flexbox';

import { ConditionsSlider } from '@/components/platform/Conditions';

import { TitleWrapper } from './styles';
import Section from '@/components/ui/Section';
import { Heading } from '@swordhealth/ui-corporate';

const Conditions = ({ content }) => {
  const { title, conditions } = content;

  if (!conditions?.data) return null;

  return (
    <Section>
      <Container large>
        <TitleWrapper>
          <Heading as="h2" size="2xl">
            {title}
          </Heading>
        </TitleWrapper>
        <ConditionsSlider conditions={conditions.data} sectionName={title} />
      </Container>
    </Section>
  );
};

export default Conditions;
