import { Container } from '@/components/ui/flexbox';
import Section from '@/components/ui/Section';

import Logo from './Logo';

import { Grid, Title } from './styles';

const LogosColumns = ({ content }) => {
  const { title, logos } = content;

  return (
    <Section>
      <Container>
        {title && <Title>{title}</Title>}
        <Grid>
          {logos.data.map((logo) => (
            <Logo url={logo?.url ?? logo?.attributes?.url} key={logo.id} />
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

export default LogosColumns;
