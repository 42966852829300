import { Container } from '@/components/ui/flexbox';
import Section from '@/components/ui/Section';

import Info from './Info';
import Wrapper from './Wrapper';
import { SectionHeader } from '@swordhealth/ui-corporate';
import { MarkdownRichText } from '@/app/_components/shared';

const CustomerValue = ({ content }) => {
  if (!content) return null;

  const {
    upperLabel = 'Testimonials',
    title,
    subtitle,
    description: quote,
    logo,
    image,
    companyName = 'Sword member',
    customerName = 'Marcelo',
    id,
  } = content;

  return (
    <Section>
      <Wrapper>
        <Container>
          <SectionHeader
            label={upperLabel}
            title={
              <MarkdownRichText elements={['strong', 'b', 'i', 'u']}>{title}</MarkdownRichText>
            }
            description={subtitle}
            titleSize="2xl"
            xAlign="center"
          />
          <Info
            id={id}
            quote={quote}
            companyName={companyName}
            customerName={customerName}
            logo={logo}
            image={image}
          />
        </Container>
      </Wrapper>
    </Section>
  );
};

export default CustomerValue;
