'use client';

import DynamicBody from '@/components/core/DynamicBody';
import { AppSupport, Footer } from '@/app/_components/platform';
import { FaqBanner, Faqs } from '@/app/components/shared';

import HeroWithCards from '@/components/platform/HeroWithCards';

import { ClientProvider } from '@/contexts/client.context';

import { products } from '@/utils/products';
import Chat from '../core/Chat';

const MainPage = ({ content }) => {
  const {
    body,
    app,
    bottomCTA,
    disclaimer,
    footnotes,
    faqs,
    contact,
    heroRolling,
    solutionConditions,
  } = content;

  const faqBanner = {
    title: 'Still have questions? Get in touch.',
  };

  return (
    <>
      <ClientProvider
        defaultClient={{
          clientKey: null,
          onboardingUrl: '',
          institutionKey: null,
          launchDate: null,
          isActive: true,
          product: products.PLATFORM,
        }}
      >
        {heroRolling && (
          <HeroWithCards content={heroRolling} solutionConditions={solutionConditions} />
        )}
        <DynamicBody body={body} />
        {faqs && <Faqs content={faqs} />}
        <FaqBanner contact={contact} content={faqBanner} />
        {app && <AppSupport content={app} />}
        <Footer
          bottomCTA={bottomCTA}
          disclaimer={disclaimer}
          footnotes={footnotes}
          scrollTop
          email={contact?.email}
        />
      </ClientProvider>
      <Chat visible={false} unit={products.PLATFORM} />
    </>
  );
};

export default MainPage;
