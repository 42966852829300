import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 125px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    height: 136px;
    align-items: unset;
    padding-top: 32px;
  }
`;

export default Wrapper;
