import { useCallback, useEffect, useId, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';

import { Slider } from './styles';

import Testimonial from './Testimonial/Testimonial';
import classNames from 'classnames';

const TestimonialsSlider = ({ quotes }) => {
  const sliderId = useId();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'center',
    containScroll: false,
    duration: 20,
    loop: true,
  });

  const isCurrentSlide = useCallback((index) => index === selectedIndex, [selectedIndex]);

  const onSelect = useCallback(() => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  return (
    <Slider ref={emblaRef}>
      <ul
        className="embla__container"
        id={sliderId}
        aria-live="polite"
        aria-roledescription="carousel"
      >
        {quotes.map((slide, index) => (
          <li
            id={`${sliderId}-${index}`}
            aria-hidden={!isCurrentSlide(index)}
            aria-roledescription="slide"
            aria-label={`${index + 1} of ${quotes.length}`}
            key={slide.id}
            className={classNames({
              embla__slide: true,
              'slide-active': isCurrentSlide(index),
            })}
          >
            <Testimonial {...slide} />
          </li>
        ))}
      </ul>
    </Slider>
  );
};

export default TestimonialsSlider;
