import getImgSrcWorkaround from '@/utils/images';
import Image from 'next/image';
import {
  CustomCard,
  ImageContainer,
  GradientIcon,
  WhiteIcon,
  CustomBlockquote,
  EllipsisParagraph,
  Quote,
  CustomPlayButton,
  PlayButton,
} from './styles';
import VideoPlayerModal from '@/components/shared/VideoPlayerModal';
import { useState } from 'react';
const LARGE_QUOTE_SIZE = 363;

const TestimonialImage = ({ url, alternativeText, ...props }) => {
  return (
    <Image
      {...props}
      src={getImgSrcWorkaround(url)}
      alt={alternativeText || ''}
      {...(props.width && props.height ? {} : { fill: true, sizes: `${LARGE_QUOTE_SIZE}px` })}
    />
  );
};

const Testimonial = ({ quote, author, byline, image, video, solution }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const hasVideo = video && video?.data?.attributes?.url !== undefined;

  return (
    <>
      {hasVideo && (
        <VideoPlayerModal
          modalIsOpen={modalIsOpen}
          closeModal={() => setModalIsOpen(false)}
          videoURL={video?.url ?? video?.data?.attributes?.url}
        />
      )}
      <CustomCard hasHoverEffect={!!video} $withImage={!!image && !hasVideo} $withVideo={hasVideo}>
        {!!image && (
          <ImageContainer>
            <TestimonialImage {...image?.data?.attributes} />
          </ImageContainer>
        )}
        {hasVideo && (
          <CustomPlayButton
            onClick={() => setModalIsOpen(true)}
            size="sm"
            variant="primary"
            rounded
          >
            <PlayButton title={`Play Testimonial Video`} />
          </CustomPlayButton>
        )}
        <CustomBlockquote $withImage={!!image && !hasVideo} $withVideo={hasVideo}>
          {!!image || hasVideo ? <WhiteIcon /> : <GradientIcon />}
          <Quote weight="semibold">{quote}</Quote>
          {(author || byline || solution) && (
            <footer>
              {author && (
                <EllipsisParagraph size="sm" weight="semibold">
                  {author}
                </EllipsisParagraph>
              )}
              {solution && (
                <EllipsisParagraph size="sm" style={{ 'text-decoration': 'underline' }}>
                  {solution}
                </EllipsisParagraph>
              )}
              {byline && <EllipsisParagraph size="sm">{byline}</EllipsisParagraph>}
            </footer>
          )}
        </CustomBlockquote>
      </CustomCard>
    </>
  );
};
export default Testimonial;
