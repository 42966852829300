import { Paragraph } from '@swordhealth/ui-corporate';
import { ChatHeader, ChatContainer, CloseButton, ParagraphMessage } from './styles';
import SBConversation from '@sendbird/uikit-react/GroupChannel';
import { MarkdownRichText } from '../../shared';
import { Message } from '@sendbird/uikit-react/GroupChannel/components/Message';

const Conversation = ({ channelUrl, status, setShowPanel }) => {
  return (
    <ChatContainer $disabled={status === 'CLOSED'}>
      <ChatHeader onClick={() => setShowPanel(false)} aria-label="close">
        <Paragraph id="chat_header_id">Your Doctor of Physical Therapy</Paragraph>
        <CloseButton>
          <span />
        </CloseButton>
      </ChatHeader>
      <SBConversation
        renderMessage={(props) => {
          if (props.message.messageType === 'admin') {
            const date = new Date(props.message.createdAt).toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            });
            return (
              <div
                class="sendbird-msg-hoc sendbird-msg--scroll-ref"
                data-testid="sendbird-message-view"
                data-sb-message-id="10182382271"
                data-sb-created-at="1734434828702"
              >
                {props.hasSeparator && (
                  <div class=" sendbird-separator">
                    <div class="sendbird-separator__left sendbird-color--onbackground-4--background-color"></div>
                    <div class="sendbird-separator__text">
                      <span class="sendbird-label sendbird-label--caption-2 sendbird-label--color-onbackground-2">
                        {date}
                      </span>
                    </div>
                    <div class="sendbird-separator__right sendbird-color--onbackground-4--background-color"></div>
                  </div>
                )}
                <div class=" sendbird-admin-message">
                  <span class="sendbird-admin-message__text sendbird-label sendbird-label--caption-2 sendbird-label--color-onbackground-2">
                    <ParagraphMessage>
                      <MarkdownRichText elements={['a']}>{props.message.message}</MarkdownRichText>
                    </ParagraphMessage>
                  </span>
                </div>
              </div>
            );
          } else return <Message {...props} />;
        }}
        channelUrl={channelUrl}
        isReactionEnabled={false}
        isMessageGroupingEnabled={false}
        isMultipleFilesMessageEnabled={false}
      />
    </ChatContainer>
  );
};
export default Conversation;
