import styled from 'styled-components';

import { Col, Row } from '@/components/ui/flexbox';
import Section, { SPACE_DESKTOP, SPACE_MOBILE, SPACE_TABLET } from '@/components/ui/Section';

export const CustomSection = styled(Section)`
  --mask-size: ${SPACE_DESKTOP}px;
  mask-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) calc(100% - var(--mask-size)),
    rgba(255, 255, 255, 0)
  );
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    --mask-size: ${SPACE_TABLET}px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    --mask-size: ${SPACE_MOBILE}px;
  }
`;
export const Indicator = styled.div`
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`;
export const SemiCircle = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 100%;

  & + & {
    margin-top: 4px;
  }

  &::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    border: 2px solid ${(props) => props.theme.colors.grey.default};
  }

  &.active {
    background-color: ${(props) => props.theme.colors.grey.default};
  }
`;
export const ImageSticky = styled.div`
  position: sticky;
  top: max(90px, -255px + 50vh);
  height: calc(100vh - 100px);
  max-height: 683px;
`;

export const MobileRow = styled(Row)`
  flex-wrap: unset;
  & + & {
    margin-top: 64px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-top: 44px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-flow: column-reverse nowrap;
    display: unset;
    margin-top: 16px;
    & + & {
      margin-top: 48px !important;
    }
  }
`;

export const TextCol = styled(Col)`
  display: flex;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 32px;
  }
`;
