import styled from 'styled-components';

const Quote = ({ id, quote, customerName, companyName }) => {
  return (
    <Wrapper>
      <QuoteText
        id={`quote-text-${id}`}
        data-testid="quote-info"
        dangerouslySetInnerHTML={{ __html: quote }}
      />
      <Name id={`quote-customer-name-${id}`} data-testid="quote-customer">
        {customerName}
      </Name>
      {companyName && (
        <Company id={`quote-company-name-${id}`} data-testid="quote-company">
          {companyName}
        </Company>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 72px 48px;
  flex: 1;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin: 40px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin: 24px;
  }
`;

const QuoteText = styled.div`
  font-family: ${(props) => props.theme.typography.family.title};
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  max-width: 418px;
  width: 100%;

  b {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    line-height: 48px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 22px;
    line-height: 32px;
    max-width: 296px;

    b {
      margin-bottom: 16px;
      font-size: 40px;
      line-height: 48px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: 100%;
    font-size: 20px;
    line-height: 28px;
  }
`;

const Name = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-top: 32px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-top: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 25px;
  }
`;

const Company = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export default Quote;
