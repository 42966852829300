import { useState } from 'react';
import styled from 'styled-components';

import Arrow from './Arrow';
import Bar from './Bar';
import Cards from '../../shared/Cards/Cards';
import Title from './Title';

import {
  StyledSection,
  StyledContainer,
  SubtitleContainer,
  TitlesWrapper,
  ArrowButton,
  DesktopButton,
  TabletButton,
} from './styles';

import { products } from '@/utils/products';
import { ClientProvider } from '@/contexts/client.context';

import SideModal from './SideModal';
import CoveredModal from '@/app/components/core/CoveredModal';
import { Paragraph } from '@swordhealth/ui-corporate';

export default function Hero({ content, solutionConditions }) {
  const { sentences, cards, title, label, showCTA, conditionModal } = content;
  const [productSelected, setProductSelected] = useState(products.PLATFORM);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  return (
    <ClientProvider
      defaultClient={{
        clientKey: null,
        institutionKey: null,
        product: productSelected,
        launchDate: null,
        isActive: true,
      }}
    >
      <Bar selectProduct={setProductSelected} />
      <CoveredModal
        coveredModal={{
          title: 'First, let’s make sure you’re covered.',
          subtitle:
            'Provide the name of the employer, health plan, or organization that offers you Sword as a benefit.',
          buttonLabel: 'Check my coverage',
          placeholder: 'E.g. Domino’s, Blue Health',
        }}
      />

      <Wrapper>
        <StyledSection colored lastColored>
          <StyledContainer large>
            <TitlesWrapper>
              <Title list={sentences} title={title} />
              {(label || showCTA) && (
                <SubtitleContainer>
                  <Paragraph>{label}</Paragraph>
                  {showCTA && conditionModal && (
                    <>
                      <SideModal
                        title={conditionModal?.title}
                        subtitle={conditionModal?.subtitle}
                        modalIsOpen={modalIsOpen}
                        setModalIsOpen={setModalIsOpen}
                        solutionConditions={solutionConditions}
                      />
                      <DesktopButton
                        variant="white"
                        onClick={() => {
                          setModalIsOpen(!modalIsOpen);
                        }}
                      >
                        {conditionModal?.buttonLabel}
                        <ArrowButton />
                      </DesktopButton>
                    </>
                  )}
                </SubtitleContainer>
              )}
            </TitlesWrapper>
            <Cards cards={cards} selectProduct={setProductSelected} />
            {showCTA && conditionModal && (
              <TabletButton
                variant="white"
                onClick={() => {
                  setModalIsOpen(!modalIsOpen);
                }}
              >
                <>
                  {conditionModal?.buttonLabel}
                  <ArrowButton />
                </>
              </TabletButton>
            )}
          </StyledContainer>
        </StyledSection>
        <Arrow />
      </Wrapper>
    </ClientProvider>
  );
}

const Wrapper = styled.div`
  position: relative;
`;
