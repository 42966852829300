export const formattedTitle = ({ title, referralUser, userName }) => {
  if (referralUser) {
    return title?.replace('USER_NAME', referralUser.inviter_first_name);
  }
  if (userName?.length) {
    return title?.replace('{{USER_NAME}}', userName);
  }
  if (!userName && title.includes('{{USER_NAME}}')) {
    const titleWithoutUsername = title
      .trim()
      .substring('{{USER_NAME}}'.length)
      .replace(/,/g, ' ')
      .trim();
    return titleWithoutUsername.charAt(0).toUpperCase() + titleWithoutUsername.slice(1);
  }
  return title;
};
