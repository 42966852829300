'use client';

import dynamic from 'next/dynamic';
import { Container } from '@/components/ui/flexbox';
import { SectionHeader } from '@swordhealth/ui-corporate';
import { MarkdownRichText } from '@/app/_components/shared';

import Section from '@/components/ui/Section';

const TestimonialsSlider = dynamic(() => import('@/app/components/shared/TestimonialsSlider'), {
  loading: () => <div style={{ height: 480 }} />,
  ssr: false,
});

const Testimonials = ({ content }) => {
  const { title, quotes } = content;
  if (!content?.quotes?.length) return null;

  return (
    <Section>
      <Container large>
        {title && (
          <Section as="header" xs="40" sm="64" md="80">
            <SectionHeader
              title={
                <MarkdownRichText elements={['strong', 'b', 'i', 'u']}>{title}</MarkdownRichText>
              }
              titleSize="2xl"
              xAlign="center"
            />
          </Section>
        )}
        <TestimonialsSlider quotes={quotes} />
      </Container>
    </Section>
  );
};

export default Testimonials;
