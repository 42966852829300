import styled from 'styled-components';

export const ImageWrapper = styled.div`
  position: relative;
  width: 50%;
  height: 480px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    height: 360px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 100%;
    height: 200px;
  }
`;

export default ImageWrapper;
