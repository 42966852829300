import styled from 'styled-components';

export const SwiperWrapper = styled.div`
  margin: 88px auto 0;
  padding: 0 107px;
  position: relative;

  &.single {
    padding: 0;
  }

  .swiper {
    border-radius: 8px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin: 64px auto 0;
    padding: 0 0 72px;

    &.single {
      padding: 0;
    }

    .swiper {
      overflow: visible;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin: 40px auto 0;
    padding: 0;

    &:not(.single) {
      .swiper {
        padding-bottom: 45px;
      }

      .swiper-pagination {
        bottom: 0;
      }

      .swiper-pagination-bullet {
        background-color: transparent;
        border: 2px solid ${(props) => props.theme.colors.grey[500]};
        opacity: 1;
      }

      .swiper-pagination-bullet-active {
        background-color: ${(props) => props.theme.colors.grey[500]};
      }
    }
  }
`;

export default SwiperWrapper;
