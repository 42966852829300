import styled from 'styled-components';

const ListComponent = ({ list }) => {
  if (!list || !list.length) {
    return null;
  }

  return (
    <List data-testid="signing-up-list">
      {list.map((item, index) => (
        <Item key={item.id} data-testid="signing-up-list-item">
          <Icon data-testid="signing-up-list-index">{index + 1}</Icon>
          <Info>
            <Title data-testid="signing-up-list-title">{item.title}</Title>
            <Description data-testid="signing-up-list-subtitle">{item.subtitle}</Description>
          </Info>
        </Item>
      ))}
    </List>
  );
};

const List = styled.div`
  width: 100%;
  margin-top: 16px;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 35px;
  }
`;

const Icon = styled.div`
  position: relative;
  height: 32px;
  width: 32px;
  min-width: 32px;
  line-height: 32px;
  text-align: center;
  border: 1px solid #1d3861;
  border-radius: 50%;
  font-size: 16px;
  font-family: ${(props) => props.theme.typography.family.title};
  font-weight: 600;
`;

const Info = styled.div`
  padding-left: 25px;
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-left: 15px;
  }
`;

const Item = styled.div`
  width: 100%;
  display: flex;
  position: relative;

  &:not(:last-child) {
    padding-bottom: 75px;
    &:before {
      position: absolute;
      content: '';
      width: 4px;
      top: 55px;
      bottom: 22px;
      left: 14px;
      background: url('/assets/signing-up/shape1.svg') no-repeat center center;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 100%;
    margin-bottom: 15px;
    padding-bottom: 35px;
    &:not(:last-child) {
      &:before {
        top: 50px;
        bottom: 4px;
      }
    }
  }

  &:last-child {
    ${Icon} {
      &:before {
        display: none;
      }
    }
  }
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.typography.family.title};
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 8px;
  font-weight: 700;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 28px;
  max-width: 360px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: unset;
  }
`;

export default ListComponent;
