import styled from 'styled-components';

import { UpperText, Label } from '@/components/ui/Title';

export const Wrapper = styled.div`
  & ${UpperText} {
    text-align: center;

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      text-align: left;
    }
  }

  h2 {
    text-align: center;
    margin: 0 auto;
    max-width: 833px;

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      text-align: left;
      margin: 0;
      max-width: 535px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      max-width: 100%;
    }
  }

  & ${Label} {
    text-align: center;
    margin: 0 auto;
    max-width: 615px;
    padding-bottom: 0;

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      max-width: 456px;
      text-align: left;
      margin: 0;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      max-width: 100%;
    }
  }
`;

export default Wrapper;
