import cn from 'classnames';
import styled from 'styled-components';

import { PAIN_CONDITIONS } from './Enums';

const PointMiddle = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${(props) => props.theme.colors.negative.default};
  border-radius: 46px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 8px;
    height: 8px;
  }
`;

const Point = styled.div`
  position: absolute;
  display: flex;
  width: 26px;
  height: 26px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(244, 98, 98, 0.3);
  transition: transform 200ms ease;
  cursor: pointer;
  z-index: 1;

  &:hover {
    transform: scale(1.4);
  }

  &.open {
    transform: scale(2);
    opacity: 1;
    z-index: 0;
  }

  &.open::before {
    content: '';
    display: block;
    width: 48px;
    height: 48px;
    position: absolute;
    background-color: ${(props) => props.theme.colors.negative.default};
    border-radius: 1000px;
    opacity: 0.2;
    z-index: 0;

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      width: 24px;
      height: 24px;
    }
  }

  &.open::after {
    content: '';
    display: block;
    width: 74px;
    height: 74px;
    position: absolute;
    background-color: ${(props) => props.theme.colors.negative.default};
    border-radius: 1000px;
    opacity: 0.1;
    z-index: 0;

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      width: 32px;
      height: 32px;
    }
  }

  &.open:hover {
    transform: scale(2);
  }

  &.open ${PointMiddle} {
    transform: scale(0.9);
  }

  &.neck {
    left: 190px;
    top: 122px;
  }

  &.shoulder {
    left: 101px;
    top: 131px;
  }

  &.elbow {
    left: 301px;
    top: 212px;
  }

  &.lower-back {
    left: 103px;
    top: 227px;
  }

  &.hip {
    left: 86px;
    top: 350px;
  }

  &.knee {
    left: 253px;
    top: 308px;
  }

  &.ankle {
    left: 152px;
    top: 726px;
  }

  &.wrist {
    left: 71px;
    top: 294px;
  }

  &.hand {
    left: 126px;
    top: 276px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    &.neck {
      left: 150px;
      top: 100px;
    }

    &.shoulder {
      left: 80px;
      top: 105px;
    }

    &.elbow {
      left: 240px;
      top: 176px;
    }

    &.lower-back {
      left: 76px;
      top: 188px;
    }

    &.hip {
      left: 67px;
      top: 280px;
    }

    &.knee {
      left: 202px;
      top: 254px;
    }

    &.ankle {
      left: 125px;
      top: 583px;
    }

    &.wrist {
      left: 54px;
      top: 234px;
    }

    &.hand {
      left: 100px;
      top: 222px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 16px;
    height: 16px;

    &.neck {
      left: 90px;
      top: 60px;
    }

    &.shoulder {
      left: 50px;
      top: 60px;
    }

    &.elbow {
      left: 140px;
      top: 110px;
    }

    &.lower-back {
      left: 50px;
      top: 120px;
    }

    &.hip {
      left: 40px;
      top: 170px;
    }

    &.knee {
      left: 110px;
      top: 140px;
    }

    &.ankle {
      left: 70px;
      top: 340px;
    }

    &.wrist {
      left: 35px;
      top: 140px;
    }

    &.hand {
      left: 60px;
      top: 140px;
    }
  }
`;

export default function PainPoints({ id, selectedPain, selectPain }) {
  return (
    <div>
      {PAIN_CONDITIONS.map((pain, index) => (
        <Point
          id={`pain-point-${id}-${index}`}
          key={pain}
          className={cn(pain, { open: index === selectedPain })}
          onClick={() => selectPain(index)}
        >
          <PointMiddle />
        </Point>
      ))}
    </div>
  );
}
