import { Label } from '@/components/ui/Title';
import { HeaderWrapper } from './styles';
import { Heading } from '@swordhealth/ui-corporate';

export default function Header({ content }) {
  const { title, subtitle } = content;

  return (
    <HeaderWrapper>
      <Heading size="2xl">{title}</Heading>
      <Label $medium>{subtitle}</Label>
    </HeaderWrapper>
  );
}
