export const PAIN_CONDITIONS = [
  'neck',
  'shoulder',
  'elbow',
  'lower-back',
  'hip',
  'wrist',
  'hand',
  'knee',
  'ankle',
];
