import { CardGrid as CorporateCardGrid } from '@swordhealth/ui-corporate';
import getImgSrcWorkaround, { getImgData } from '@/utils/images';
import { SPACE_DESKTOP, SPACE_MOBILE, SPACE_TABLET } from '@/components/ui/Section';
import { useMemo } from 'react';
import styled from 'styled-components';

const transformCardList = (cardList) =>
  cardList.map(
    ({ title, description, button_label, button_href, image, background, imageFit, xAlign }) => {
      const imageData = getImgData(image);
      return {
        title,
        description,
        textLink: {
          href: button_href,
          label: button_label,
        },
        image: {
          url: getImgSrcWorkaround(imageData?.url || ''),
          alternativeText: imageData?.alternativeText || '',
        },
        imageFit,
        xAlign,
        background,
      };
    },
  );

const CardGrid = ({ content }) => {
  const { cardHero, backgroundGradient, buttonGroupPosition, cardList, columnCount, layout } =
    content;

  const transformedCardList = useMemo(() => transformCardList(cardList), [cardList]);

  return (
    <StyledCardGrid
      sectionHeader={{ label: cardHero?.upperLabel, xAlign: 'left', ...cardHero }}
      cardList={transformedCardList}
      backgroundGradient={backgroundGradient}
      buttonGroupPosition={buttonGroupPosition}
      columnCount={columnCount}
      layout={layout}
    />
  );
};

export default CardGrid;

const StyledCardGrid = styled(CorporateCardGrid)`
  clip-path: circle(farthest-side at 50% -10000px);
  margin-bottom: ${SPACE_DESKTOP};

  h2 {
    font-size: 40px;
    line-height: 32px;
    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      font-size: 28px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      font-size: 22px;
    }
  }

  span {
    --button-color: var(--ui-text-link-active-color-primary);

    &:hover {
      --button-hover-color: var(--ui-button-hover-color-text);
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    clip-path: circle(farthest-side at 50% -4000px);
    margin-bottom: ${SPACE_TABLET};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    clip-path: circle(farthest-side at 50% -2500px);
    margin-bottom: ${SPACE_MOBILE};
  }
`;
