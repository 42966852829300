import { Hero } from '@swordhealth/ui-corporate';

import Media from './Media';

const HeroMedia = ({ content }) => {
  const { media, mediaFit, mediaRatio } = content.media;

  return (
    <Hero {...content} mediaRatio={mediaRatio || undefined} className={'hero-media'}>
      <Media {...media?.data?.attributes} objectFit={mediaFit} priority />
    </Hero>
  );
};

export default HeroMedia;
