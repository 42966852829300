import NextImage from 'next/image';
import styled from 'styled-components';
import { scrollToSection } from '@/utils/scrollToSection';

export default function Arrow() {
  return (
    <Wrapper
      onClick={() => scrollToSection({ sectionId: 'dynamic-zone', isReduced: false, margin: 120 })}
    >
      <WrapperInner>
        <Inner>
          <Image
            loading="lazy"
            alt="Sword logo"
            src="/assets/black-arrow.svg"
            height={20}
            width={20}
          />
        </Inner>
      </WrapperInner>
    </Wrapper>
  );
}

const Wrapper = styled.button`
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  height: 80px;
  width: 80px;
  border: 2px solid #efeae6;
  border-radius: 50%;
  text-align: center;
  animation: word-anim 3s infinite linear;
  cursor: pointer;

  @keyframes word-anim {
    0% {
      transform: translateX(-50%) translateY(0px);
    }

    50% {
      transform: translateX(-50%) translateY(-10px);
    }

    100% {
      transform: translateX(-50%) translateY(0px);
    }
  }
`;

const WrapperInner = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
`;

const Inner = styled.div`
  border-radius: 50%;
  background-color: #efeae6;
  height: 56px;
  width: 56px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
`;

const Image = styled(NextImage)`
  transform: rotate(90deg);
  margin: auto;
`;
