import { Container } from '@/components/ui/flexbox';

import Card from './Card';

import { Grid, TitleWrapper } from './styles';
import Section from '@/components/ui/Section';
import { SectionHeader } from '@swordhealth/ui-corporate';

const ThreeColumnsCards = ({ content }) => {
  const { title, card } = content;

  return (
    <Section>
      <Container>
        {title && (
          <TitleWrapper as="h2">
            <SectionHeader title={title} titleSize="2xl" xAlign="start" />
          </TitleWrapper>
        )}
        <Grid>
          {card.map((item) => (
            <Card {...item} key={item.title} />
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

export default ThreeColumnsCards;
