import { COMPONENTS } from './components';

const getComponent = (componentKey) => {
  if (!COMPONENTS[componentKey]) {
    console.error(`Component ${componentKey} doesn't exist.`);
    return false;
  }

  return COMPONENTS[componentKey];
};

export default function DynamicComponent({ body, ...props }) {
  if (!body) {
    console.error("Please provide a 'body' property to the DynamicComponent");
    return;
  }

  return (
    <div id="dynamic-zone">
      {body.map((bodyItem) => {
        const Component = getComponent(bodyItem['__component']);

        if (!Component) {
          return '';
        }
        return (
          <Component
            content={bodyItem}
            {...bodyItem}
            isOldPage={true}
            hasSection={true}
            key={`${bodyItem['__component']}-${bodyItem.id}`}
            id={`${bodyItem['__component']}-${bodyItem.id}`}
            {...props}
          />
        );
      })}
    </div>
  );
}
