import styled, { css } from 'styled-components';

const SliderWrapper = styled.div`
  max-width: 68%;
  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
      max-width: 100%;
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: unset;
    padding-bottom: 40px;

    .slick-slider {
      .slick-dots {
        li {
          width: 8px;
          height: 8px;
          margin: 0 4px;

          button {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            width: 8px;
            height: 8px;

            &:before {
              position: relative;
              content: '';
              width: 8px;
              height: 8px;
              background-color: transparent;
              border: 2px solid ${(props) => props.theme.colors.grey[500]};
              border-radius: 50%;
              opacity: 1;
            }
          }
        }

        .slick-active button:before {
          background-color: ${(props) => props.theme.colors.grey[500]};
        }
      }
    }
  }
`;

export default SliderWrapper;
