import styled from 'styled-components';

import { Container } from '@/components/ui/flexbox';

const StyledSlider = styled(Container)`
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 0;
  }
`;

export default StyledSlider;
