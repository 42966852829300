import styled from 'styled-components';

export const ImageWrapper = styled.div`
  position: relative;
  width: 727px;
  height: 496px;
  margin-left: 23px;
  border-radius: 10px;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    width: 100%;
    min-width: 296px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    height: 368px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-left: 0;
    margin-top: 24px;
    min-width: 272px;
    height: 256px;
  }
`;

export default ImageWrapper;
