import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import { useScreenClass } from 'react-grid-system';
import classNames from 'classnames';

import { Container } from '@/components/ui/flexbox';
import Section from '@/components/ui/Section';

import Wrapper from './Wrapper';
import SwiperWrapper from './SwiperWrapper';
import Info from './Info';
import NavigationWrapper from './NavigationWrapper';

import ArrowLeftIcon from '@/public/icons/circled-arrow-left.svg';
import ArrowRightIcon from '@/public/icons/circled-arrow-right.svg';

import 'swiper/css/bundle';
import { SectionHeader } from '@swordhealth/ui-corporate';

const Testimonials = ({ content }) => {
  const { id, upperLabel, title, subtitle, testimonialItem } = content;
  const screenClass = useScreenClass();

  return (
    <Section>
      <Wrapper>
        <Container large>
          <SectionHeader
            title={title}
            description={subtitle}
            label={upperLabel}
            titleSize="2xl"
            xAlign="center"
          />

          <SwiperWrapper className={classNames({ single: testimonialItem.length <= 1 })}>
            <Swiper
              modules={[Navigation, Pagination, A11y]}
              spaceBetween={24}
              slidesPerView={1}
              navigation={{
                enabled: !['xs', 'sm'].includes(screenClass) && testimonialItem.length > 1,
                prevEl: '.prev-slide-button',
                nextEl: '.next-slide-button',
              }}
              pagination={['xs', 'sm'].includes(screenClass)}
              allowTouchMove={testimonialItem.length > 1}
              loop
            >
              {testimonialItem.map((item) => (
                <SwiperSlide key={item.id}>
                  <Info
                    id={item.id}
                    quote={item.quote}
                    author={item.author}
                    authorRole={item.authorRole}
                    image={item.image}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <NavigationWrapper className={classNames({ hidden: testimonialItem.length <= 1 })}>
              <ArrowLeftIcon id={`testimonials-arrow-left-${id}`} className="prev-slide-button" />
              <ArrowRightIcon id={`testimonials-arrow-right-${id}`} className="next-slide-button" />
            </NavigationWrapper>
          </SwiperWrapper>
        </Container>
      </Wrapper>
    </Section>
  );
};

export default Testimonials;
