import styled from 'styled-components';

export const QuoteContainer = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.primary.light};
  width: 514px;
  max-height: 496px;
  border-radius: 10px;
  display: flex;

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    min-width: 376px;
    width: 100%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    height: 368px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    justify-content: flex-start;
    min-width: 270px;
    height: 320px;
  }
`;

export default QuoteContainer;
