import styled from 'styled-components';
import Image from 'next/image';

import getImgSrcWorkaround from '@/utils/images';

export const getWidth = (width, height, isMobile) => {
  if (isMobile) {
    return (width * 32) / height;
  }
  return (width * 40) / height;
};

const Companies = ({ list }) => (
  <>
    {list.map((company, i) => {
      const width = getWidth(company.attributes.width, company.attributes.height);
      return (
        <Wrapper key={company.attributes.url} style={{ width }} data-testid="company-wrap">
          <Image
            id={`company-image-${i}`}
            alt={company?.alternativeText ?? company.attributes.alternativeText ?? ''}
            src={getImgSrcWorkaround(company?.url ?? company?.attributes?.url)}
            fill
            quality={100}
          />
        </Wrapper>
      );
    })}
  </>
);

const Wrapper = styled.div`
  height: 32px;
  margin-left: 88px;
  position: relative;

  img {
    object-fit: contain;
    object-position: center center;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-left: 40px;
  }
`;

export default Companies;
