import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Heading, Paragraph } from '@swordhealth/ui-corporate';

const Title = ({ title, list }) => {
  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (messageIndex + 1 < list.length) {
        setMessageIndex(messageIndex + 1);
      } else {
        setMessageIndex(0);
      }
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [messageIndex]);

  return (
    <TitleWrapper>
      <TitleHeading>
        <Heading as="h1" size="3xl">
          {title}
          <span> {list[messageIndex]?.sentence}</span>
        </Heading>
      </TitleHeading>
      <Paragraph>Begin your treatment</Paragraph>
    </TitleWrapper>
  );
};

export default Title;

const TitleWrapper = styled.div`
  h1 {
    span {
      color: ${(props) => props.theme.colors.primary[500]};
    }
  }
  p {
    margin-top: 8px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    h1 {
      display: inline-grid;
    }
  }
`;

const TitleHeading = styled.div`
  height: 96px;
  overflow: hidden;
  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    height: 112px;
  }
`;
