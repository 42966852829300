import Image from 'next/image';
import styled, { css } from 'styled-components';

export const CustomImage = styled(Image)`
  max-width: 560px;
  max-height: 560px;
  ${(props) =>
    props.$objectFit === 'contain' &&
    css`
      object-fit: contain;
    `};

  ${(props) =>
    props.$objectFit === 'cover' &&
    css`
      object-fit: cover;
    `};
`;
