import styled from 'styled-components';

export const NavigationWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.hidden {
    display: none;
  }

  svg {
    height: 64px;
    width: 64px;
    cursor: pointer;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    justify-content: flex-start;
    align-items: flex-end;

    svg {
      height: 48px;
      width: 48px;
      margin-right: 12px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    display: none;
  }
`;

export default NavigationWrapper;
