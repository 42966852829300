import { H2, Label, UpperText } from '@/components/ui/Title';
import { Col, Row } from '@/components/ui/flexbox';

export default function Header({ label, title, subTitle }) {
  return (
    <Row>
      <Col md={12} xs={12}>
        <UpperText>{label}</UpperText>
        <H2>{title}</H2>
        <Label>{subTitle}</Label>
      </Col>
    </Row>
  );
}
