import { Button, Card, Paragraph } from '@swordhealth/ui-corporate';
import styled, { css } from 'styled-components';
import QuoteGradientIcon from '@/public/icons/icon-quote-gradient.svg';
import PlayIcon from '@/public/assets/icons/ic_play.svg';

export const ImageContainer = styled.div`
  display: grid;
  left: 0;
  height: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  width: 100%;
  overflow: hidden;
  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
`;

export const CustomCard = styled(Card)`
  --testimonials-slider-item-width: 22.5rem;
  justify-content: center;
  overflow: hidden;
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 280px;
    height: 480px;
  }

  ${(props) =>
    (props.$withImage || props.$withVideo) &&
    css`
      gap: var(--ui-spacings-sm);
      --testimonials-slider-item-height: 560px;
      height: var(--testimonials-slider-item-height);
      display: flex;
      flex-direction: column;
      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        --testimonials-slider-item-height: 480px;
      }
    `};

  ${(props) =>
    props.$withImage &&
    css`
      justify-content: end;
    `};

  ${(props) =>
    props.$withVideo &&
    css`
      justify-content: space-between;
    `};
`;

export const GradientIcon = styled(QuoteGradientIcon)`
  width: 32px;
  height: 32px;
`;

export const WhiteIcon = styled(QuoteGradientIcon)`
  width: 32px;
  height: 32px;
  path {
    fill: white;
  }
`;
export const CustomBlockquote = styled.blockquote`
  display: flex;
  flex-direction: column;
  gap: var(--ui-spacings-sm);
  z-index: 1;
  ${(props) =>
    (props.$withImage || props.$withVideo) &&
    css`
      color: var(--ui-colors-white);
      background: linear-gradient(
        to bottom,
        rgb(from var(--ui-colors-grey-dark) r g b / 0) 0,
        rgb(from var(--ui-colors-grey-dark) r g b / 60%) 20%,
        rgb(from var(--ui-colors-grey-dark) r g b / 90%)
      );
      color: var(--ui-colors-white);
      margin: auto calc(var(--card-padding) * -1) calc(var(--card-padding) * -1);
      padding: var(--card-padding);
      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        max-height: 344px;
      }
    `};
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-height: 392px;
  }
`;
export const EllipsisParagraph = styled(Paragraph)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CustomPlayButton = styled(Button)`
  --button-bg: var(--ui-colors-grey-dark);
  --button-border: var(--ui-colors-grey-dark);
  --button-svg-size: 16px;
  & > span {
    width: 40px;
    height: 40px;
  }
`;

export const PlayButton = styled(PlayIcon)``;
export const Quote = styled(Paragraph)`
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
    overflow: hidden;
  }
`;
