import styled, { css } from 'styled-components';
import { Accordion, AccordionItem } from '@/components/core/Accordion';
import { Button, Dropdown } from '@swordhealth/ui-corporate';

export const Header = styled.div`
  margin-bottom: 40px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-bottom: 32px;
  }
`;

export const CustomAccordion = styled(Accordion)`
  position: relative;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 8px;
    border: 1px solid #e7eaf0;
  }
`;
export const CustomAccordionItem = styled(AccordionItem)`
  &:first-child {
    border-top: 1px solid transparent;
  }

  ${(props) =>
    props.$isBodyPartsSection &&
    css`
      h3 {
        padding: 24px 0;
      }
    `}

  .title-wrapper {
    span {
      font-size: 18px;
      font-weight: ${(props) => props.theme.font.weight.medium};

      ${(props) =>
        props.$isBodyPartsSection &&
        css`
          line-height: 32px;
          color: ${(props) => props.theme.colors.grey[600]};
          font-weight: ${(props) => props.theme.font.weight.bold};

          &.is-open {
            color: ${(props) => props.theme.colors.grey[900]};
          }
        `}
    }

    i {
      min-height: 30px;
      min-width: 30px;
      padding: 7.5px;
      background-color: ${(props) => props.theme.colors.primary.light};
      border-radius: 50%;

      &:before,
      &:after {
        background-color: ${(props) => props.theme.colors.grey.dark};
        height: 1px;
      }
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    &:last-child {
      border-bottom: 1px solid transparent;
    }
  }
`;

export const StyledContent = styled.div`
  position: relative;
  margin: auto;
  max-width: 962px;
`;

export const CategoriesList = styled.ul`
  position: relative;
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-bottom: 40px;
`;

export const StyledCategory = styled(Button)`
  font-size: 14px;
  line-height: 22px;

  ${(props) =>
    props.$selectedCategory &&
    css`
      color: ${(props) => props.theme.colors.primary.default};
      border-bottom: ${(props) => props.theme.colors.primary.default} 2px solid;
    `};

  &:focus,
  &:hover {
    color: ${(props) => props.theme.colors.primary.default};
  }

  &:focus-visible {
    color: ${(props) => props.theme.colors.black};
    outline-color: ${(props) => props.theme.colors.grey[300]};
  }
`;

export const StyledDropdown = styled(Dropdown)`
  padding-bottom: 32px;
  & > div:first-of-type {
    --field-box-bg: ${(props) => props.theme.colors.neutral[200]};
  }
`;
