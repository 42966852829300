import { memo } from 'react';

import { bustMediaCache, Video } from '@swordhealth/ui-corporate';
import { CustomImage } from './styles';
import getImgSrcWorkaround from '@/utils/images';

function Media({
  alternativeText = '',
  mime,
  objectFit,
  url,
  updatedAt,
  priority = false,
  ...props
}) {
  const src = bustMediaCache(url, updatedAt);

  if (!src || !mime) {
    return null;
  }

  if (mime.includes('video')) {
    return (
      <Video
        {...props}
        url={src}
        mime={mime}
        objectFit={objectFit || 'cover'}
        data-testid="media-video"
      />
    );
  }

  if (mime.includes('svg')) {
    return <img {...props} src={src} alt={alternativeText || ''} data-testid="media-svg" />;
  }

  if (mime.includes('image')) {
    return (
      <CustomImage
        $objectFit={objectFit}
        src={getImgSrcWorkaround(src)}
        alt={alternativeText || ''}
        fill
        data-testid="media-image"
        priority={priority}
      />
    );
  }

  return null;
}

export default memo(Media);
