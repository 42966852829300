import { useSearchParams } from 'next/navigation';

import { Button } from '@swordhealth/ui-corporate';

import { trackEvent } from '@/lib/gtm';

import { ACTIONS, BUTTON_TYPES, trackButtonClicked } from '@/utils/track';

import { scrollToTop } from '@/utils/scrollToSection';

import useReducedMotion from '@/utils/useReducedMotion';
import {
  createGuardaLink,
  createOnboardingURL,
  createMoveD2COnboardingURL,
} from '@/utils/onboarding-urls';
import { compareDates } from '@/utils/dates';

export const redirectToOnboarding = (buttonLabel, client, buttonLocation, href, triage) => {
  trackEvent('redirect_to_onboarding', {
    value: client.clientKey,
  });

  trackButtonClicked({
    action: triage ? ACTIONS.REDIRECT_TO_TRIAGE : ACTIONS.REDIRECT_TO_ONBOARDING,
    buttonType: BUTTON_TYPES.NAVIGATION,
    buttonText: buttonLabel,
    destination: href,
    buttonLocation: buttonLocation,
  });
};

export default function EnrollButton({
  id,
  isActive,
  buttonLocation,
  openModal,
  client,
  buttonLabel = 'Get started',
  scrollTop,
  product,
  redirectToApp,
  buttonApp,
  triage,
  referralUser,
  isPortugueseLP,
}) {
  const searchParams = useSearchParams();
  const isReduced = useReducedMotion();

  if (product?.key?.toLowerCase() === 'move_d2c') {
    return (
      <Button id={id} href={createMoveD2COnboardingURL()} data-testid="enroll-d2c">
        {buttonLabel}
      </Button>
    );
  }

  if (!isActive) {
    return (
      <Button id={id} data-testid="enroll-disabled" disabled>
        {buttonLabel}
      </Button>
    );
  }

  if (compareDates(client?.launchDate)) {
    const params = new URLSearchParams(searchParams?.toString());
    params.append('client', client.clientKey);
    return (
      <Button id={id} href={'/waiting-list?' + params.toString()} data-testid="enroll-soon">
        Coming Soon
      </Button>
    );
  }

  if (referralUser) {
    const href = createOnboardingURL({
      clientKey: client.institutionKey ?? client.clientKey,
      countryCode: referralUser?.region,
      programType: product?.param,
      query: { ...Object.fromEntries(searchParams), 'referral-token': referralUser?.token },
      triage,
    });

    return (
      <Button
        id={id}
        onClick={() => redirectToOnboarding(buttonLabel, client, buttonLocation, href, triage)}
        href={href}
        data-testid="enroll-active"
      >
        {buttonLabel}
      </Button>
    );
  }

  if (redirectToApp && client?.clientKey) {
    const link = createGuardaLink({
      clientKey: client.institutionKey ?? client.clientKey,
      serviceApp: product?.app,
      programType: product?.param,
    });
    return (
      <Button id={id} href={link}>
        {buttonApp?.label ?? product?.enrollLabel ?? 'Enroll Now'}
      </Button>
    );
  }

  if (scrollTop || (redirectToApp && !client.clientKey)) {
    return (
      <Button id={id} onClick={() => scrollToTop({ isReduced })} data-testid="enroll-scroll">
        {buttonLabel}
      </Button>
    );
  }

  if (client?.clientKey && (!client?.countries || client?.countries?.length < 2) && buttonLabel) {
    const href = !client?.onboardingUrl
      ? createOnboardingURL({
          clientKey: client.institutionKey ?? client.clientKey,
          countryCode: client?.country?.data?.attributes?.ISOCode,
          programType: product?.param,
          query: Object.fromEntries(searchParams),
          triage,
        })
      : client?.onboardingUrl;
    return (
      <Button
        id={id}
        onClick={() => redirectToOnboarding(buttonLabel, client, buttonLocation, href, triage)}
        href={href}
        data-testid="enroll-active"
      >
        {buttonLabel}
      </Button>
    );
  }

  if (isPortugueseLP) {
    const href = '/thrive/registo/formulario';
    return (
      <Button
        id={`${id}-enroll-button-pt`}
        href={href}
        data-testid="enroll-pt"
        onClick={() => {
          trackButtonClicked({
            action: ACTIONS.REDIRECT_TO_FORM,
            buttonType: BUTTON_TYPES.NAVIGATION,
            buttonText: { buttonLabel },
            destination: { href },
            buttonLocation: buttonLocation,
          });
        }}
      >
        {buttonLabel}
      </Button>
    );
  }

  return (
    <Button id={id} onClick={openModal} data-testid="enroll-modal">
      {buttonLabel}
    </Button>
  );
}
