import styled from 'styled-components';

import { Col } from '@/components/ui/flexbox';

const MainTitleCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleWrapper = styled.div`
  max-width: 429px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-bottom: 50px;
    text-align: center;
  }
`;

export { TitleWrapper, MainTitleCol };
