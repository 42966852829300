import NextImage from 'next/image';
import styled, { css } from 'styled-components';

import { Paragraph, RichText } from '@swordhealth/ui-corporate';
import { getImgData } from '@/utils/images';

const UnevenGrid = ({ cards, sectionId }) => {
  return (
    <Grid id={`uneven-grid-${sectionId}`}>
      {cards.map((item, index) => {
        const imgData = getImgData(item.image?.data);
        return (
          <Card key={item.id} $index={index} id={`card-uneven-grid-${index}`}>
            <Wrapper $index={index}>
              <TitlesWrapper>
                <RichText as="h3" gradientBoldText id={`card-title-${sectionId}-${index}`}>
                  {item.title}
                </RichText>

                <RichText as={Paragraph} id={`card-description-${sectionId}-${index}`}>
                  {item.description}
                </RichText>
              </TitlesWrapper>

              {item.image?.data && index < 2 && (
                <NextImage
                  id={`card-image-${sectionId}-${index}`}
                  src={imgData?.url}
                  alt={imgData?.alternativeText ?? ''}
                  fill
                />
              )}
            </Wrapper>
          </Card>
        );
      })}
    </Grid>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, minmax(auto, 302px));
  gap: 16px;
  padding-top: var(--ui-spacings-lg);

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) and (max-width: ${(props) =>
      props.theme.breakpoints.max_md}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, minmax(auto, 274px));
    grid-template-areas:
      'card1 card1'
      'card2 card3'
      'card4 card4';
    padding-top: var(--ui-spacings-sm);

    #card-uneven-grid-0 {
      grid-area: card1;
      grid-column: 1 / -1;
    }
    #card-uneven-grid-2 {
      grid-area: card2;
    }
    #card-uneven-grid-3 {
      grid-area: card3;
    }
    #card-uneven-grid-1 {
      grid-area: card4;
      grid-column: 1 / -1;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'card1'
      'card2'
      'card3'
      'card4';
    padding-top: 8px;

    #card-uneven-grid-0 {
      grid-area: card1;
    }
    #card-uneven-grid-2 {
      grid-area: card2;
    }
    #card-uneven-grid-3 {
      grid-area: card3;
    }
    #card-uneven-grid-1 {
      grid-area: card4;
    }
  }
`;

const Card = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  border-radius: 32px;
  background: ${(props) => props.theme.colors.neutral[200]};
  overflow: hidden;

  img {
    object-fit: contain;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.min_sm}px) {
    img {
      object-position: bottom;
      margin-top: 0;
    }
  }

  ${(props) =>
    props.$index === 0 &&
    css`
      display: flex;
      grid-column: 1 / 2;
      grid-row: 1 / 3;

      img {
        position: unset !important;
        display: flex;
        justify-content: flex-end;
        object-position: bottom;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        img {
          position: unset !important;
          object-position: right;
        }
      }

      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        display: flex;
        flex-direction: column;

        img {
          display: flex;
          object-fit: contain !important;
          object-position: unset !important;
          position: unset !important;
        }
      }
    `}

  ${(props) =>
    props.$index === 1 &&
    css`
      display: flex;
      grid-column: 2 / 4;
      grid-row: 1 / 2;

      img {
        position: unset !important;
        object-position: right;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        img {
          position: unset !important;
          object-position: right;
          width: auto !important;
        }
      }

      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        flex-direction: column;

        img {
          position: unset !important;
          display: flex;
          object-position: unset !important;
        }
      }
    `}

    ${(props) =>
    props.$index === 2 &&
    css`
      grid-column: 2 / 3;
      grid-row: 2 / 3;
    `}

    ${(props) =>
    props.$index === 3 &&
    css`
      grid-column: 3 / 4;
      grid-row: 2 / 3;
    `}
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  ${(props) =>
    props.$index === 0 &&
    css`
      flex-direction: column;

      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        flex-direction: row;
      }
      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        flex-direction: column;
      }
    `}

  ${(props) =>
    props.$index === 1 &&
    css`
      flex-direction: row;
      justify-content: space-between;

      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        flex-direction: column;
      }
    `}
`;

const TitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px;
  gap: var(--ui-spacings-sm);

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 100%;
    align-items: center;
    padding: var(--ui-spacings-lg);
  }

  h3 {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -1px;

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      font-size: 28px;
      line-height: 32px;
      letter-spacing: -0.5px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      text-align: center;
      font-size: 22px;
      line-height: 26px;
    }
  }

  p {
    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      font-size: 14px;
      line-height: 24px;
      text-align: center;
    }
  }
`;

export default UnevenGrid;
