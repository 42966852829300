import styled from 'styled-components';
import Section from '@/components/ui/Section';
import { Modal } from '@swordhealth/ui-corporate';
import Close from '@/public/icons/circle-close.svg';

export const CustomModal = styled(Modal)`
  & > button:first-of-type {
    z-index: 9;
  }
  & > div:first-of-type {
    position: absolute;
    top: 0;
    right: 0;
    margin: -24px;
    min-height: 100vh;
    background-color: white;

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      width: 100vw;
      max-width: unset;
    }
  }
`;
export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--ui-spacings-lg);
  h2 {
    font-size: var(--ui-font-heading-2xl-size-lg);
    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      font-size: var(--ui-font-heading-2xl-size-md);
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-direction: column-reverse;
    gap: var(--ui-spacings-sm);
    h2 {
      font-size: var(--ui-font-heading-2xl-size-sm);
    }
  }
`;
export const CloseButton = styled.button`
  min-width: 48px;
  height: 48px;
  z-index: 100001;
  cursor: pointer;
  transform: scale(1);
  transition: all 0.3s ease;
  border-radius: 50%;
  border: 1px solid var(--ui-colors-grey-800);
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    transform: scale(1.1);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    top: -48px;
    width: 32px;
    height: 32px;
    min-width: 32px;
    align-self: end;
  }
`;
export const IconClose = styled(Close)`
  width: 16px;
  height: 16px;
  & > path {
    fill: var(--ui-colors-grey-800);
  }
  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    width: 20px;
    height: 20px;
  }
`;

export const Wrapper = styled(Section)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: var(--ui-spacings-xl);
  border-radius: 16px;
  gap: 16px;
  overflow: visible;
  background-color: white;
  width: 100vw;
  max-width: 620px;
  height: 100vh;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: 100vw !important;
    padding: var(--ui-spacings-md);
  }
`;

export const Content = styled.div`
  padding: var(--ui-spacings-2xl) 0;
  margin-bottom: -var(--ui-spacings-2xl);
`;

export const UnitHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--ui-spacings-sm);
  margin-bottom: var(--ui-spacings-sm);
  color: var(--ui-colors-grey-700);
  width: 460px;
  font-size: var(--ui-font-heading-2xl-size-lg);
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 545px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 100%;
  }
`;
export const ImageWrapper = styled.div`
  height: 14px;
  position: relative;
  & > img {
    width: auto !important;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: var(--ui-spacings-lg);
  & > a {
    width: 100%;
    height: 84px;
    border-radius: unset;
    border-bottom: 1px solid ${(props) => props.theme.colors.grey[200]};
    & > span {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
`;
