import Image from 'next/image';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

import { Container } from '@/components/ui/flexbox';

import getImgSrcWorkaround from '@/utils/images';

import Description from './Description';
import Info from './Info';
import Wrapper from './Wrapper';

import { Heading } from '@swordhealth/ui-corporate';
import Section from '@/components/ui/Section';

const GetBetter = ({ content }) => {
  const { id, title } = content;

  return (
    <Section>
      <Container small>
        <Wrapper>
          <Info>
            <Heading id={`get-better-title-${id}`} size="2xl">
              <ReactMarkdown allowedElements={['strong', 'underline']} unwrapDisallowed>
                {title}
              </ReactMarkdown>
            </Heading>
            <Description id={`get-better-description-${id}`} data-testid="get-better-text">
              {content.description}
            </Description>
          </Info>
          {content.image && (
            <ImageWrapper>
              <Image
                id={`get-better-image-${id}`}
                src={getImgSrcWorkaround(
                  content?.image?.url ?? content?.image?.data?.attributes?.url,
                )}
                alt={
                  content?.image?.alternativeText ??
                  content?.image?.data?.attributes?.alternativeText ??
                  ''
                }
                fill
                data-testid="get-better-image"
              />
            </ImageWrapper>
          )}
        </Wrapper>
      </Container>
    </Section>
  );
};

const ImageWrapper = styled.div`
  position: relative;
  max-width: 450px;
  width: 100%;
  height: 501px;
  min-height: 501px;

  img {
    object-fit: contain;
    object-position: center center;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    min-height: 208px;
    height: 208px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: 100%;
    margin-bottom: 24px;
  }
`;

export default GetBetter;
