import React, { useState, createContext, useContext } from 'react';
import { ACTIONS, trackButtonClicked } from '@/utils/track';
import { products } from '@/utils/products';

const ClientContext = createContext();

export function useClientContext() {
  return useContext(ClientContext);
}

export const ClientProvider = ({ children, defaultClient }) => {
  const [client, setClient] = useState(
    defaultClient || {
      clientKey: null,
      onboardingUrl: '',
      product: products.THRIVE,
      institutionKey: null,
      launchDate: null,
      isActive: true,
      hasTriage: false,
      redirectToApp: false,
      loginOnboarding: false,
    },
  );
  const [selectClientCountry, setSelectClientCountry] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [product, setProduct] = useState(defaultClient?.product || products.THRIVE);

  return (
    <ClientContext.Provider
      value={{
        client,
        setClient,
        selectClientCountry,
        setSelectClientCountry,
        product,
        setProduct,
        modalIsOpen,
        closeModal: () => setModalIsOpen(false),
        openModal: ({ buttonText, buttonLocation }) => {
          setModalIsOpen(true);
          if (buttonText && buttonLocation) {
            trackButtonClicked({
              action: ACTIONS.OPEN_MODAL,
              buttonText: buttonText,
              buttonLocation: buttonLocation,
            });
          }
        },
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};

export default ClientContext;
